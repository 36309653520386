/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the hook used to get the form context.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to create a context.
 */
import * as React from 'react';

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */
import { IFieldState, IFieldSubscription } from '../FormManager';

import useScopedField from './useScopedField';

import { IInputActions } from '../components/Input';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Functions
 * ---------------------------------------------------------------------------------
 */

const useScopedInput = <TValue = any, TError = any>(path: string, subscription?: Partial<IFieldSubscription>, autoRegister?: boolean): { state: IFieldState<TValue, TError>, actions: IInputActions<TValue, TError>} => {
    const scopedField = useScopedField<TValue, TError>(path, subscription, autoRegister);

    const actions: IInputActions<TValue, TError> = React.useMemo(() => {
        return {
            blur: () => {
                scopedField.context.setFocused(false);
            },
            clean: () => {
                scopedField.context.setDirty(false);
            },
            dirty: () => {
                scopedField.context.setDirty(true);
            },
            focus: () => {
                scopedField.context.setFocused(true);
            },
            touch: () => {
                scopedField.context.setTouched(true);
            },
            untouch: () => {
                scopedField.context.setTouched(false);
            },
            update: (newValue: TValue | null | undefined, updateDirty?: boolean, updateTouched?: boolean, updateFocused?: boolean) => {
                scopedField.context.setValue(newValue, updateDirty, updateTouched, updateFocused);
            }
        }
    }, [scopedField.context.setValue, scopedField.context.setFocused, scopedField.context.setTouched, scopedField.context.setDirty]);

    const scopedInput = React.useMemo(() => {
        return {
            state: scopedField.state,
            actions
        };
    }, [scopedField.state, actions])

    return scopedInput;
};

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default useScopedInput;