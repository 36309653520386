/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains a hook that proxies a hook from 
 * online-patient-management-reducers making less types required to use the hook.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/**
 * Required to make use of JSX functionality
 */
import * as React from 'react';

import { makeStyles, Theme } from '@material-ui/core/styles';

import Alert from '@material-ui/lab/Alert';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faInfoCircle } from '@fortawesome/pro-duotone-svg-icons/faInfoCircle';

import AlertTitle from '@material-ui/lab/AlertTitle';

import { faExclamationCircle } from '@fortawesome/pro-duotone-svg-icons/faExclamationCircle';

import { faTimesCircle } from '@fortawesome/pro-duotone-svg-icons/faTimesCircle';
/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import * as Dtos from '../../api/dtos';
import Markdown from './Markdown';
import { Typography } from '@material-ui/core';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */
interface IValidationErrorSummaryProps {
    validationErrors?: Dtos.IValidationError[] | null;
    detailedMessage?: boolean
}


/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles<Theme>(theme => ({
    error: {
        display: 'flex',

        [theme.breakpoints.down('sm')]: {
            display: 'block'
        }
    },
    code: {
        whiteSpace: 'nowrap',
        padding: theme.spacing(1, 1, 1, 0),

        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(0)
        }
    },
    codeText: {
        fontWeight: 'bold'
    },
    message: {
        padding: theme.spacing(1, 0, 1, 1),

        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(0)
        }
    }
}));


const useAlertStyles = makeStyles<Theme>(theme => ({
    message: {
        minWidth: '0%'
    }
}));

/*
 * ---------------------------------------------------------------------------------
 * components
 * ---------------------------------------------------------------------------------
 */

const ValidationErrorSummary: React.FunctionComponent<IValidationErrorSummaryProps> = ({
    validationErrors,
    detailedMessage,
    children
}) => {
    const classes = useStyles();

    const notes = React.useMemo(() => validationErrors?.filter(e => e.type === Dtos.ValidationErrorType.Warning), [validationErrors]);

    const eligibilities = React.useMemo(() => validationErrors?.filter(e => e.type === Dtos.ValidationErrorType.Ineligible), [validationErrors]);

    const errors = React.useMemo(() => validationErrors?.filter(e => e.type === Dtos.ValidationErrorType.Normal), [validationErrors]);

    const stratifications = React.useMemo(() => validationErrors?.filter(e => e.type === Dtos.ValidationErrorType.StratificationFailure), [validationErrors]);

    const criticals = React.useMemo(() => validationErrors?.filter(e => e.type === Dtos.ValidationErrorType.Critical), [validationErrors]);

    const alertClasses = useAlertStyles();

    return (
        <>
            {
                notes && notes.length > 0 && (
                    <Alert
                        icon={<FontAwesomeIcon icon={faInfoCircle} fixedWidth />}
                        severity="info"
                        classes={alertClasses}
                    >
                        <AlertTitle>
                            Note
                        </AlertTitle>
                        {
                            notes.map(note => {
                                return (
                                    <div
                                        className={classes.error}
                                        key={note.code}
                                    >
                                        <div
                                            className={classes.code}
                                        >
                                            <Typography
                                                variant="button"
                                                className={classes.codeText}
                                            >
                                                {note.code}
                                            </Typography>
                                        </div>
                                        <div
                                            className={classes.message}
                                        >
                                            <Markdown>
                                                {detailedMessage ? note.detailedMessage : note.message}
                                            </Markdown>
                                        </div>
                                    </div>
                                );
                            })
                        }
                    </Alert>
                )
            }
            {
                eligibilities && eligibilities.length > 0 && (
                    <Alert
                        icon={<FontAwesomeIcon icon={faExclamationCircle} fixedWidth />}
                        severity="warning"
                        classes={alertClasses}
                    >
                        <AlertTitle>
                            Eligibility
                        </AlertTitle>
                        {
                            eligibilities.map(eligibility => {
                                return (
                                    <div
                                        className={classes.error}
                                        key={eligibility.code}
                                    >
                                        <div
                                            className={classes.code}
                                        >
                                            <Typography
                                                variant="button"
                                                className={classes.codeText}
                                            >
                                                {eligibility.code}
                                            </Typography>
                                        </div>
                                        <div
                                            className={classes.message}
                                        >
                                            <Markdown>
                                                {detailedMessage ? eligibility.detailedMessage : eligibility.message}
                                            </Markdown>
                                        </div>
                                    </div>
                                );
                            })
                        }
                    </Alert>
                )
            }
            {
                errors && errors.length > 0 && (
                    <Alert
                        icon={<FontAwesomeIcon icon={faTimesCircle} fixedWidth />}
                        severity="error"
                        classes={alertClasses}
                    >
                        <AlertTitle>
                            Error
                        </AlertTitle>
                        {
                            errors.map(e => {
                                return (
                                    <div
                                        className={classes.error}
                                        key={e.code}
                                    >
                                        <div
                                            className={classes.code}
                                        >
                                            <Typography
                                                variant="button"
                                                className={classes.codeText}
                                            >
                                                {e.code}
                                            </Typography>
                                        </div>
                                        <div
                                            className={classes.message}
                                        >
                                            <Markdown>
                                                {detailedMessage ? e.detailedMessage : e.message}
                                            </Markdown>
                                        </div>
                                    </div>
                                );
                            })
                        }
                    </Alert>
                )
            }
            {
                stratifications && stratifications.length > 0 && (
                    <Alert
                        icon={<FontAwesomeIcon icon={faExclamationCircle} fixedWidth />}
                        severity="warning"
                        variant="filled"
                        classes={alertClasses}
                    >
                        <AlertTitle>
                            Stratification
                        </AlertTitle>
                        {
                            stratifications.map(stratification => {
                                return (
                                    <div
                                        className={classes.error}
                                        key={stratification.code}
                                    >
                                        <div
                                            className={classes.code}
                                        >
                                            <Typography
                                                variant="button"
                                                className={classes.codeText}
                                            >
                                                {stratification.code}
                                            </Typography>
                                        </div>
                                        <div
                                            className={classes.message}
                                        >
                                            <Markdown>
                                                {detailedMessage ? stratification.detailedMessage : stratification.message}
                                            </Markdown>
                                        </div>
                                    </div>
                                );
                            })
                        }
                    </Alert>
                )
            }
            {
                criticals && criticals.length > 0 && (
                    <Alert
                        icon={<FontAwesomeIcon icon={faTimesCircle} fixedWidth />}
                        severity="error"
                        variant="filled"
                        classes={alertClasses}
                    >
                        <AlertTitle>
                            Critical
                        </AlertTitle>
                        {
                            criticals.map(critical => {
                                return (
                                    <div
                                        className={classes.error}
                                        key={critical.code}
                                    >
                                        <div
                                            className={classes.code}
                                        >
                                            <Typography
                                                variant="button"
                                                className={classes.codeText}
                                            >
                                                {critical.code}
                                            </Typography>
                                        </div>
                                        <div
                                            className={classes.message}
                                        >
                                            <Markdown>
                                                {detailedMessage ? critical.detailedMessage : critical.message}
                                            </Markdown>
                                        </div>
                                    </div>
                                );
                            })
                        }
                    </Alert>
                )
            }
        </>
    )
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default ValidationErrorSummary;
