/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * This file contains the component that provides context for the online patient
 * management system.
 * ---------------------------------------------------------------------------------
 */

/*
 * ----------------------------------------------------------------------------------
 * Imports - External
 * ----------------------------------------------------------------------------------
 */

/*
 * Required to use React components.
 */
import * as React from 'react';
import { makeStyles, Button, ButtonProps, CircularProgress, CircularProgressProps } from '@material-ui/core';
import classNames from 'classnames';


/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

/**
 * This interface defines the properties for the ProgressButton component.
 */
export interface IProgressButtonProps extends ButtonProps {
    loading?: boolean;
    ProgressProps?: CircularProgressProps;
    spacing?: number;
    containerClass?: string;
}

/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles(theme => ({
    buttonProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: (props: any) => half(props.size),
        marginLeft: (props: any) => half(props.size),
    },
    wrapper: {
        position: 'relative',
        display: 'inline-flex',
        margin: (props: any) => props.spacing !== undefined ? theme.spacing(props.spacing) : theme.spacing(1),
    }
}));

/*
 * ---------------------------------------------------------------------------------
 * Components
 * ---------------------------------------------------------------------------------
 */
const half = (value: string | number) => {
    if (typeof value === 'string') {
        return `-calc(${value} / 2)`;
    }
    else {
        return (value / 2) * -1;
    }
};


const getProgressSize = (buttonSize: ButtonProps['size']) => {
    switch (buttonSize) {
        case 'large':
            return 26;
        case 'small':
            return 22;
        default:
            return 24;
    }
};

const getProgressColor = (buttonColor: ButtonProps['color']): CircularProgressProps['color'] => {
    switch (buttonColor) {
        case 'primary':
            return 'secondary';
        case 'secondary':
            return 'primary';
        default:
            return 'inherit';
    }
};

/**
 * This component provides context for the patient management system.
 * @param param0 component properties.
 */
const ProgressButton: React.FunctionComponent<IProgressButtonProps> = ({
    loading,
    disabled,
    ProgressProps,
    spacing,
    containerClass,
    ...rest
}) => {
    const progressProps = React.useMemo(() => {
        return {
            ...ProgressProps,
            size: ProgressProps?.size ?? getProgressSize(rest.size),
            color: ProgressProps?.color ?? getProgressColor(rest.color)
        }
    }, [ProgressProps, rest.size, rest.color])

    const classes = useStyles({ size: progressProps.size, spacing });

    return (
        <div className={classNames(classes.wrapper, containerClass)}>
            <Button
                disabled={disabled === undefined ? loading : disabled}
                {...rest}
            />
            {
                loading && (
                    <CircularProgress
                        {...progressProps}
                        className={progressProps.className ? `${progressProps.className} ${classes.buttonProgress}` : classes.buttonProgress}
                    />
                )
            }
        </div>
    );
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default ProgressButton;