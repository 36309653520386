import { FormErrorBoundary, FormErrorBoundaryMode, IFieldProps as IBaseFieldProps, IInputProps, Input, InputComponentType, OmitInputRender } from '@ngt/forms-core';
import * as React from 'react';
import LabelContext from '../../../contexts/form/LabelContext';
import FieldProvider from '../FieldProvider';
import LookupFilter, {ILookupFilterProps} from '../LookupFilter';

type OmitProps<T> = Omit<T, 'name'>;

interface IFieldOnlyProps<TValue = any> extends OmitProps<IBaseFieldProps<TValue>> {
    errorMode?: FormErrorBoundaryMode;
}

export interface IFieldProps<
    TComponent extends InputComponentType = any,
    TValue = any,
    TForm extends object = any
    > extends IInputProps<TComponent, TValue> {
    name: string
    FieldProps?: IFieldOnlyProps<TValue>
    LookupFilterProps?: ILookupFilterProps<TForm>
}

const MuiFieldInternal = <
    TComponent extends InputComponentType = any,
    TValue = any
>({
    name,
    FieldProps: { errorMode = FormErrorBoundaryMode.Contain, ...fieldProps } = { errorMode: FormErrorBoundaryMode.Contain },
    ...props
}: IFieldProps<TComponent, TValue> & Partial<OmitInputRender<React.ComponentProps<TComponent>>>) => {

    const labelContext = React.useContext(LabelContext);

    return <Input name={name} label={labelContext?.label} {...props} />
};

const Field = <
    TComponent extends InputComponentType = any,
    TValue = any
>({
    name,
    FieldProps: { errorMode = FormErrorBoundaryMode.Contain, ...fieldProps } = { errorMode: FormErrorBoundaryMode.Contain },
    LookupFilterProps,
    ...props
}: IFieldProps<TComponent, TValue> & Partial<OmitInputRender<React.ComponentProps<TComponent>>>) => {

    let internal = <MuiFieldInternal name={name} {...props} />;

    if(LookupFilterProps)
    {
        internal = <LookupFilter {...LookupFilterProps}>
            {internal}
        </LookupFilter>;
    }

    return (
        <FormErrorBoundary
            mode={errorMode}
        >
            <FieldProvider name={name} {...fieldProps}>
                {internal}
            </FieldProvider>
        </FormErrorBoundary>
    )
};

export default Field;