/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the component that provides the basis for all routes.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to create a context.
 */
import * as React from 'react';

/*
 * Used to type properties and get access to routes. 
 */
import { RouteProps } from 'react-router';


/*
 * Used to get access to application routing based on paths.
 */
import { Route, useParams, match } from 'react-router-dom';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to load forms into the FormByIdContext
 */
import FormByIdResolver, { IFormMapping } from '../../resolver/data/FormByIdResolver';

/**
 * Used to get OPMS settings
 */
import FormsContext from '../../../contexts/FormsContext';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

/**
 * This interface defines the properties for the OpmsFormByIdRoute component.
 */
export interface IFormRouteProps extends RouteProps {
    formDefinitionCode: string;
    id?: number;

    computedMatch?: match<Record<string, string>>;

    /**
     * This property determines whether the associated data should be loaded before loading the route.
     */
    resolveBeforeLoad?: boolean | null;

    formMapping?: Record<string, IFormMapping>;

    allowCreate?: boolean;
}

/*
 * ---------------------------------------------------------------------------------
 * Components
 * ---------------------------------------------------------------------------------
 */

/**
 * This component provides context for the form management system.
 * @param param0 component properties.
 */
const FormRoute: React.FunctionComponent<IFormRouteProps> = ({
    formDefinitionCode,
    id,
    resolveBeforeLoad,
    children,
    formMapping,
    allowCreate,
    ...routeProps
}) => {
    const formsContext = React.useContext(FormsContext)
    const params = routeProps.computedMatch?.params ?? useParams<Record<string, string>>();
    const paramFormIdStr = params[formsContext.routeParameters.formId];
    const paramFormDefCode = params[formsContext.routeParameters.formDefinitionCode];
    let paramId = null;

    try {
        if (paramFormIdStr) {
            paramId = parseInt(paramFormIdStr);
        }
    }
    catch (error) {
        console.error(`Invalid ID in route: ${paramFormIdStr}`)
    }

    return <Route {...routeProps}>
        <FormByIdResolver
            formDefinitionCode={paramFormDefCode ?? formDefinitionCode ?? ''}
            id={paramId ?? id ?? null}
            resolveBeforeLoad={resolveBeforeLoad}
            formMapping={formMapping}
            allowCreate={allowCreate}
        >
            {children}
        </FormByIdResolver>
    </Route>
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default FormRoute;