/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * This file contains the component that provides context for the online patient
 * management system.
 * ---------------------------------------------------------------------------------
 */

/*
 * ----------------------------------------------------------------------------------
 * Imports - External
 * ----------------------------------------------------------------------------------
 */

/*
 * Used to type / create the client used to communicate to the API.
 */
import { JsonServiceClient } from '@servicestack/client';

/*
 * Used to type / create the history used within the app.
 */
import { History, createBrowserHistory } from 'history';

/*
 * Used to type / create a redux store; 
 */
import { Store, Middleware, compose, createStore, applyMiddleware } from 'redux';

/*
 * Used to connect history to store.
 */
import { createReduxHistoryContext } from 'redux-first-history';

/*
 * Used to create the redux middleware that handles running all side effect logic.
 */
import { createLogicMiddleware, Logic, LogicMiddleware } from 'redux-logic';

/*
 * Used to listen to async requests handled through redux side effects.
 */
import createReduxPromiseListener, { ReduxPromiseListener } from 'redux-promise-listener';

/*
 * Used to connect redux with redux dev tools.
 */
import { composeWithDevTools } from 'redux-devtools-extension';

/*
 * Used to help type actions created by immer-reducer
 */
import { ActionCreators } from 'immer-reducer';

/*
 * Used to type theme objects for MUI.
 */
import { ThemeOptions, Theme, createMuiTheme } from '@material-ui/core';

/*
 * Used for extending base class:
 *      - ReducerRegistry
 * Used to subscribe the store to updates from a reducer registry.
 */
import {
    subscribeToReducerRegistry,
    combineLogics,
    combineReducers,
    ReducerRegistry
} from '@ngt/reducer-registry-logics';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to type form metadata
 */
import * as Dtos from './api/dtos';

/*
 * Used to register the authenticatedUser reducer;
 */
import registerAuthenticatedUserReducer from './store/modules/authenticatedUser';

/*
 * Used to register the formDefinition reducer;
 */
import registerFormDefinitionReducer from './store/modules/configuration/formDefinition';

/*
 * Used to register the formDefinitions reducer;
 */
import registerFormDefinitionsReducer from './store/modules/configuration/formDefinitions';

/*
 * Used to register the form reducer;
 */
import registerFormReducer, { FormReducer } from './store/modules/data/form';

/*
 * Used to register the forms reducer;
 */
import registerFormsReducer, { FormsReducer } from './store/modules/data/forms';

/*
 * Used to register the forms reducer;
 */
import registerLookupsReducer from './store/modules/utility/lookups';

/*
 * Used to register form reducers and their actions. 
 */
import ActionReducerRegistry from './utilities/ActionRegistryReducer';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

/**
 * This interface defines the properties for the route parameters that are used by
 * the OPMS.
 */
interface IFormsRouteParameterOptions {
    
    /**
     * The route parameter name for Form Definition ID
     */
    formDefinitionId: string;

    /**
     * The route parameter name for Form Definition Code
     */
    formDefinitionCode: string;

    /**
     * The route parameter name for Form ID
     */
    formId: string;
};

interface IFormsThemeOptions {
    beforeThemeCreated?: (themeOptions: ThemeOptions) => ThemeOptions;
    afterThemeCreated?: (theme: Theme) => Theme;
}

interface IFormsReducerOptions {
    authenticatedUser?: boolean | null;
    form?: boolean | null;
    forms?: boolean | null;

    formDefinition?: boolean | null;
    formDefinitions?: boolean | null;
    
    lookups?: boolean | null;
};

interface IFormsStoreOptions {
    preloadState?: any | null;
    onMiddlewaresCombined?: (middlewares: Middleware[]) => Middleware[];
    afterStoreCreated?: (store: Store) => Store;
    initialiseReducers?: boolean | null | IFormsReducerOptions;
};

interface IFormsOrgOptions {
    organisationLogo?: string;
    organisationName?: string;
}

export interface IFormsOptions {
    /**
     * The Reducer Registry to use throughout the application.
     * 
     * (Automatically created if not provided).
     */
    reducerRegistry?: ReducerRegistry | null;

    /**
     * The ServiceStack client to use throughout the application.
     *
     * (Automatically created if not provided).
     */
    serviceStackClient?: JsonServiceClient | null;

    /**
     * The History to use throughout the application.
     *
     * (Automatically created if not provided).
     */
    history?: History | null;

    /**
     * The configuration options to use when creating the redux store.
     *
     * (Defaults used if not provided).
     */
    storeOptions?: IFormsStoreOptions | null;

    /**
     * The configuration options for route parameters used throughout the app.
     *
     * (Defaults used if not provided).
     */
    routeParameters?: Partial<IFormsRouteParameterOptions> | null;

    /**
     * The configuration options to use when creating the theme.
     *
     * (Defaults used if not provided).
     */
    themeOptions?: Partial<IFormsThemeOptions> | null;

    /**
     * The configuration options to use when creating the redux store.
     *
     * (Defaults used if not provided).
     */
    dtos: Record<string, any>;

    /**
     * The configuration options for the trial name and logo.
     *
     * (Defaults used if not provided).
     */
    organisationOptions?: IFormsOrgOptions | null;

    formMetadata?: Dtos.FormMetadata[] | null;

    extensions?: IFormsExtension[] | null;
}

export interface IFormsExtension {
    initialiseReducers?: (opms: Forms) => void;
    ProviderComponent?: React.ComponentType<{ children: React.ReactNode }>;
    ContentComponent?: React.ComponentType<{ children: React.ReactNode }>;
}

/*
 * ---------------------------------------------------------------------------------
 * Classes
 * ---------------------------------------------------------------------------------
 */


/**
 * This class handles the global context used by the OPMS.
 */
export class Forms
{
    /**
     * The name of the organisation running the trial.
     */
    public organisationName: string;

    /**
     * The logo for the organisation running the trial.
     */
    public organisationLogo: string | null;

    /**
     * Basic metadata about the forms available in the OPMS.
     */
    public formMetadata: Dtos.FormMetadata[];

    /**
     * The Reducer Registry used throughout the app.
     * 
     * Allows reducers and their associated logic to be registered which automatically
     * adds them to the redux store.
     */
    public reducerRegistry: ReducerRegistry;

    /**
     * The Form Reducer Registry used throughout the app.
     * 
     * Allows reducers and their associated logic and actions to be registered which automatically
     * adds them to the redux store.
     */
    public formReducerRegistry: ActionReducerRegistry<ActionCreators<typeof FormReducer>>;

    /**
     * The Forms Reducer Registry used throughout the app.
     * 
     * Allows reducers and their associated logic and actions to be registered which automatically
     * adds them to the redux store.
     */
    public formsReducerRegistry: ActionReducerRegistry<ActionCreators<typeof FormsReducer>>;

    /**
     * The ServiceStack client used throughout the app.
     * 
     * Allows for requests to be sent to the server side ServiceStack instance.
     */
    public serviceStackClient: JsonServiceClient;

    /**
     * The History used throughout the app.
     * 
     * Allows for the router and other components to control the navigation of the
     * app.
     */
    public history: History;

    /**
     * The Redux Store used throughout the app.
     * 
     * Allows for the management of a global application state.
     */
    public store: Store;

    /**
     * The Logic Middleware used within the Redux Store.
     * 
     * Allows for side effects to be run based off action types received by the
     * Redux Store.
     */
    public logicMiddleware: LogicMiddleware;

    /**
     * The Redux Promise Listener used within the Redux Store.
     * 
     * Allows for actions with resultant actions (i.e. load -> loadSuccess) to be
     * transformed into promises (async / await);
     */
    public reduxPromiseListener: ReduxPromiseListener;

    /**
     * The names of all the Route Parameters used throughout the app.
     * 
     * Allows for the naming of the Route Parameters to be changed.
     */
    public routeParameters: IFormsRouteParameterOptions;

    /**
     * The MUI theme used throughout the app.
     * 
     * Allows for global theming and styling to applied to the app.
     */
    public theme: Theme;

    /**
     * The DTOs used throughout the app.
     * 
     * Allows for typed communication with the server side ServiceStack instance.
     */
    public dtos: any;

    /**
     * The Extensions applied to the OPMS.
     */
    public extensions: IFormsExtension[];

    /**
     * Creates a new OnlinePatientManagement using the provided configuration options.
     * @param options Configuration Options
     */
    constructor(options: IFormsOptions) {
        this.initialiseExtensions(options?.extensions);
        this.initialiseTrialInformation(options?.organisationOptions);
        this.initialiseFormMetadata(options?.formMetadata);
        this.initialiseReducerRegistry(options?.reducerRegistry);
        this.initialiseServiceStackClient(options?.serviceStackClient);
        this.initialiseHistory(options?.history);
        this.initialiseDtos(options?.dtos);
        this.initialiseRouteParameters(options?.routeParameters);
        this.initialiseTheme(options?.themeOptions);
        this.initialiseStore(options?.storeOptions, this.extensions);
    }

    /**
     * This method initialises the trial information.
     * @param trialOptions Configured Trial Options.
     */
    private initialiseExtensions(extensions?: IFormsExtension[] | null) {
        this.extensions = extensions ?? [];
    }

    /**
     * This method initialises the trial information.
     * @param trialOptions Configured Trial Options.
     */
    private initialiseTrialInformation(trialOptions?: IFormsOrgOptions | null) {
        this.organisationName = trialOptions?.organisationName ?? 'Unknown Organisation';
        this.organisationLogo = trialOptions?.organisationLogo ?? null;
    }

    /**
     * This method initialises the form metadata.
     * @param trialOptions Configured Trial Options.
     */
    private initialiseFormMetadata(formMetadata?: Dtos.FormMetadata[] | null) {
        this.formMetadata = formMetadata ?? [];
    }

    /**
     * This method initialises the Reducer Registry.
     * @param reducerRegistry Configured reducer registry.
     */
    private initialiseReducerRegistry(reducerRegistry?: ReducerRegistry | null) {

        if (reducerRegistry) {
            // If reducer registry has been provided, use it.

            // Check that the reducer registry is of the correct object type.
            if (reducerRegistry instanceof ReducerRegistry) {
                throw new Error("The provided Reducer Registry was not of the correct type.")
            }

            this.reducerRegistry = reducerRegistry;
        }
        else {
            // If no reducer registry was provided, create a new empty registry.
            this.reducerRegistry = new ReducerRegistry();
        }
    }

    /**
     * This method initialises the ServiceStack client.
     * @param serviceStackClient Configured ServiceStack Client.
     */
    private initialiseServiceStackClient(serviceStackClient?: JsonServiceClient | null) {

        if (serviceStackClient) {
            // If ServiceStack Client has been provided, use it.

            // Check that the ServiceStack Client is of the correct object type.
            if (serviceStackClient instanceof JsonServiceClient) {
                throw new Error("The provided ServiceStack Client was not of the correct type.")
            }

            this.serviceStackClient = serviceStackClient;
        }
        else {
            // If no ServiceStack Client was provided, create a new Servicestack Client.
            this.serviceStackClient = new JsonServiceClient();
        }
    }

    /**
     * This method initialises the History.
     * @param history Configured history.
     */
    private initialiseHistory(history?: History | null) {
        if (history) {
            // If history has been provided, use it.
            this.history = history;
        }
        else {
            // If no history was provided, create a new history.
            this.history = createBrowserHistory();
        }
    }

    /**
     * This method initialises the DTOs.
     * @param dtos Configured DTOs
     */
    private initialiseDtos(dtos?: Record<string, any> | null) {
        if (dtos) {
            // If DTOs have been provided, use it.
            this.dtos = dtos;
        }
        else {
            // If no DTOs were provided, throw errors.
            throw new Error("There was no DTOs provided.")
        }
    }

    /**
     * This method initialises the Route Parameter Names.
     * @param routeParameters Configured Route Parameter Names.
     */
    private initialiseRouteParameters(routeParameters?: Partial<IFormsRouteParameterOptions> | null) {
        this.routeParameters = {
            formDefinitionId: routeParameters?.formDefinitionId ?? 'formDefinitionId',
            formDefinitionCode: routeParameters?.formDefinitionCode ?? 'formDefinitionCode',
            formId: routeParameters?.formId ?? 'formId'
        }
    }

    /**
     * This method initialises the Theme.
     * @param themeOptions Configured Theme Options
     */
    private initialiseTheme(themeOptions?: IFormsThemeOptions | null) {

        // Create default MUI Theme Options
        let muiThemeOptions: ThemeOptions = {
            typography: {
                h1: {
                    fontSize: '3rem'
                },
                h2: {
                    fontSize: '1.5rem'
                },
                h3: {
                    fontSize: '1.25rem'
                },
                h4: {
                    fontSize: '1.20rem'
                },
                h5: {
                    fontSize: '1.15rem'
                },
                h6: {
                    fontSize: '1.10rem'
                }
            }
        };

        // Update MUI Theme Options using event function if provided.
        if (themeOptions?.beforeThemeCreated) {
            muiThemeOptions = themeOptions.beforeThemeCreated(muiThemeOptions);
        }

        // Create MUI Theme from MUI Theme Options.
        let muiTheme = createMuiTheme(muiThemeOptions);

        // Update MUI Theme using event function if provided.
        if (themeOptions?.afterThemeCreated) {
            muiTheme = themeOptions.afterThemeCreated(muiTheme);
        }

        // Set MUI Theme;
        this.theme = muiTheme;
    }

    /**
     * This method initialises the Reducers in the Reducer Registry.
     * @param reducerOptions Configured Reducer Options.
     */
    private initialiseReducers(reducerOptions?: IFormsReducerOptions | boolean | null, extensions?: IFormsExtension[] | null) {
        if (extensions) {
            extensions.forEach((extension) => {
                if (extension.initialiseReducers) {
                    extension.initialiseReducers(this);
                }
            });
        }

        // Check if reducer initialisation was requested to be skipped.
        if (reducerOptions === false) {
            return;
        }

        if (reducerOptions === true || reducerOptions?.authenticatedUser !== false) {
            // Register Authenticated User Reducer if requested.
            registerAuthenticatedUserReducer(this.serviceStackClient, this.reducerRegistry);
        }

        if (reducerOptions === true || reducerOptions?.formDefinition !== false) {
            // Register FormDefinition Reducer if requested.
            registerFormDefinitionReducer(this.serviceStackClient, this.reducerRegistry);
        }

        if (reducerOptions === true || reducerOptions?.formDefinitions !== false) {
            // Register FormDefinitions Reducer if requested.
            registerFormDefinitionsReducer(this.serviceStackClient, this.reducerRegistry);
        }

        if (reducerOptions === true || reducerOptions?.lookups !== false) {
            // Register Events Reducer if requested.
            registerLookupsReducer(this.serviceStackClient, this.reducerRegistry);
        }

        if (reducerOptions === true || reducerOptions?.form !== false) {
            this.formReducerRegistry = new ActionReducerRegistry<ActionCreators<typeof FormReducer>>();

            this.formMetadata.forEach(formMetadata => {
                // Register Form Reducer if requested.
                registerFormReducer(formMetadata, this.dtos, this.serviceStackClient, this.formReducerRegistry);
            });

            const formReducers = combineReducers(this.formReducerRegistry.getReducers());
            const formLogics = combineLogics(this.formReducerRegistry.getSideEffects());

            this.reducerRegistry.register('form', formReducers, formLogics);

            this.formReducerRegistry.setOnRegister((reducers, sideEffects) => {
                const reducer = combineReducers(reducers)
                const logic = combineLogics(sideEffects)

                this.reducerRegistry.register('form', reducer, logic);
            });
        }

        if (reducerOptions === true || reducerOptions?.forms !== false) {
            this.formsReducerRegistry = new ActionReducerRegistry<ActionCreators<typeof FormsReducer>>();

            this.formMetadata.forEach(formMetadata => {
                // Register Forms Reducer if requested.
                registerFormsReducer(formMetadata, this.dtos, this.serviceStackClient, this.formsReducerRegistry);
            });

            const formsReducers = combineReducers(this.formsReducerRegistry.getReducers());
            const formsLogics = combineLogics(this.formsReducerRegistry.getSideEffects());

            this.reducerRegistry.register('forms', formsReducers, formsLogics);

            this.formsReducerRegistry.setOnRegister((reducers, sideEffects) => {
                const reducer = combineReducers(reducers)
                const logic = combineLogics(sideEffects)

                this.reducerRegistry.register('forms', reducer, logic);
            });
        }
    }

    /**
     * This method initialises the Redux Store.
     * @param storeOptions Configured Redux Store Options
     */
    private initialiseStore(storeOptions?: IFormsStoreOptions | null, extensions?: IFormsExtension[] | null) {
        this.initialiseReducers(storeOptions?.initialiseReducers, extensions);

        // Create connected history and associated middleware.
        const {
            routerMiddleware,
            routerReducer
        } = createReduxHistoryContext({
            history: this.history,
            reduxTravelling: process.env.NODE_ENV !== 'production'
            // others options if needed
        });

        // Register Router Reducer in Reducer Registry.
        this.reducerRegistry.register('router', routerReducer);

        // Combine all currently registered reducers to create the root reducer.
        const reducer = combineReducers(
            this.reducerRegistry.getReducers(),
            storeOptions?.preloadState
        );

        // Create logic middleware with the currently registered logic.
        this.logicMiddleware = createLogicMiddleware(
            combineLogics(this.reducerRegistry.getSideEffects())
        );

        // Create Redux Promise Listener to turn async side effects into promises.
        this.reduxPromiseListener = createReduxPromiseListener();

        // Setup the default middleware used by the online patient management.
        let middleware: Middleware[] = [];

        if (process.env.NODE_ENV === 'production') {
            // Setup production middleware
            applyMiddleware(routerMiddleware);
            middleware.push(this.logicMiddleware);
            middleware.push(this.reduxPromiseListener.middleware);
        }
        else {
            // Setup development middleware
            const logger = require('redux-logger').default;

            middleware.push(routerMiddleware);
            middleware.push(this.logicMiddleware);
            middleware.push(this.reduxPromiseListener.middleware);
            middleware.push(logger);
        }

        // Update middleware using event function if provided
        if (storeOptions?.onMiddlewaresCombined) {
            middleware = storeOptions?.onMiddlewaresCombined(middleware);
        }

        // Connect the redux devtools extension if not production
        const composeEnhancers =
            process.env.NODE_ENV !== 'production' ?
                composeWithDevTools :
                compose;

        // Create store
        this.store = createStore(
            reducer,
            storeOptions?.preloadState as any,
            composeEnhancers(applyMiddleware(...middleware as any) as any)
        );

        // Bind on reducer registration call back to the reducer registry to allow for
        // updating of reducers while the store is in use.
        subscribeToReducerRegistry(
            this.store,
            this.reducerRegistry,
            this.logicMiddleware,
            storeOptions?.preloadState
        );
    }
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default Forms;