/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the hook used to initialise a form..
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to create a context.
 */
import * as React from 'react';

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to remove the first parameter from the base action creator function type.
 */
import FormManager, { IFormManagerOptions } from '../FormManager';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

export interface IUseFormOptions<TValues extends object = any, TError = any> extends IFormManagerOptions<TValues, TError> {
}

export interface IUseFormReturn<TValues extends object = any, TError = any> {
    getValues: FormManager<TValues, TError>['getFormValues'];
    getTouched: FormManager<TValues, TError>['getFormTouched'];
    getDirty: FormManager<TValues, TError>['getFormDirty'];
    getFocused: FormManager<TValues, TError>['getFormFocused'];
    getErrors: FormManager<TValues, TError>['getFormErrors'];
    getInitialValues: FormManager<TValues, TError>['getFormInitialValues'];
    getSubmitting: FormManager<TValues, TError>['getFormSubmitting'];
    getValidating: FormManager<TValues, TError>['getFormValidating'];
    setValues: FormManager<TValues, TError>['setFormValues'];
    setTouched: FormManager<TValues, TError>['setFormTouched'];
    setDirty: FormManager<TValues, TError>['setFormDirty'];
    setFocused: FormManager<TValues, TError>['setFormFocused'];
    setErrors: FormManager<TValues, TError>['setFormErrors'];
    setValidating: FormManager<TValues, TError>['setFormValidating'];
    setSubmitting: FormManager<TValues, TError>['setFormSubmitting'];
    getFieldValue: FormManager<TValues, TError>['getFieldValue'];
    getFieldTouched: FormManager<TValues, TError>['getFieldTouched'];
    getFieldDirty: FormManager<TValues, TError>['getFieldDirty'];
    getFieldFocused: FormManager<TValues, TError>['getFieldFocused'];
    getFieldErrors: FormManager<TValues, TError>['getFieldErrors'];
    getFieldInitialValue: FormManager<TValues, TError>['getFieldInitialValue'];
    setFieldValue: FormManager<TValues, TError>['setFieldValue'];
    setFieldTouched: FormManager<TValues, TError>['setFieldTouched'];
    setFieldDirty: FormManager<TValues, TError>['setFieldDirty'];
    setFieldFocused: FormManager<TValues, TError>['setFieldFocused'];
    setFieldErrors: FormManager<TValues, TError>['setFieldErrors'];
    reset: FormManager<TValues, TError>['reset'];
    submit: FormManager<TValues, TError>['submit'];
    validate: FormManager<TValues, TError>['validate'];
    subscribe: FormManager<TValues, TError>['subscribeToForm'];
    subscribeToField: FormManager<TValues, TError>['subscribeToField'];
    registerField: FormManager<TValues, TError>['registerField'];
    unregisterField: FormManager<TValues, TError>['unregisterField'];
    getFields: FormManager<TValues, TError>['getFields'];
    notifyFormChange: FormManager<TValues, TError>['notifyFormChange'];
    notifyFieldChange: FormManager<TValues, TError>['notifyFieldChange'];
}

/*
 * ---------------------------------------------------------------------------------
 * Functions
 * ---------------------------------------------------------------------------------
 */

const useForm = <TValues extends object = any, TError = any>(options?: IUseFormOptions<TValues, TError>) => {
    const form = React.useMemo(() => {
        return new FormManager<TValues, TError>(options);
    }, []);

    React.useEffect(() => {
        form.setAllowSubmit(options?.allowSubmit);
    }, [form.setAllowSubmit, options?.allowSubmit])

    React.useEffect(() => {
        form.setInitialValues(options?.initialValues);
    }, [form.setInitialValues, options?.initialValues])

    React.useEffect(() => {
        form.setOnSubmit(options?.onSubmit);
    }, [form.setOnSubmit, options?.onSubmit])

    React.useEffect(() => {
        form.setOnValidate(options?.onValidate);
    }, [form.setOnValidate, options?.onValidate])

    React.useEffect(() => {
        form.setValidateOn(options?.validateOn);
    }, [form.setValidateOn, options?.validateOn])

    const formMethods: IUseFormReturn<TValues, TError> = React.useMemo(() => {
        return {
            getValues: form.getFormValues,
            getTouched: form.getFormTouched,
            getDirty: form.getFormDirty,
            getFocused: form.getFormFocused,
            getErrors: form.getFormErrors,
            getInitialValues: form.getFormInitialValues,
            getSubmitting: form.getFormSubmitting,
            getValidating: form.getFormValidating,
            setValues: form.setFormValues,
            setTouched: form.setFormTouched,
            setDirty: form.setFormDirty,
            setFocused: form.setFormFocused,
            setErrors: form.setFormErrors,
            setValidating: form.setFormValidating,
            setSubmitting: form.setFormSubmitting,
            getFieldValue: form.getFieldValue,
            getFieldTouched: form.getFieldTouched,
            getFieldDirty: form.getFieldDirty,
            getFieldFocused: form.getFieldFocused,
            getFieldErrors: form.getFieldErrors,
            getFieldInitialValue: form.getFieldInitialValue,
            setFieldValue: form.setFieldValue,
            setFieldTouched: form.setFieldTouched,
            setFieldDirty: form.setFieldDirty,
            setFieldFocused: form.setFieldFocused,
            setFieldErrors: form.setFieldErrors,
            reset: form.reset,
            submit: form.submit,
            validate: form.validate,
            subscribe: form.subscribeToForm,
            subscribeToField: form.subscribeToField,
            getFields: form.getFields,
            registerField: form.registerField,
            unregisterField: form.unregisterField,
            notifyFormChange: form.notifyFormChange,
            notifyFieldChange: form.notifyFieldChange
        };
    }, [form]);

    return formMethods;
};

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default useForm;