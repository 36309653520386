/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the table component
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/**
 * React
 */
import * as React from 'react';

import { makeStyles, Theme, useTheme, SvgIcon } from '@material-ui/core';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faPlusSquare } from '@fortawesome/pro-duotone-svg-icons/faPlusSquare';
import { faCheck } from '@fortawesome/pro-duotone-svg-icons/faCheck';
import { faTimes } from '@fortawesome/pro-duotone-svg-icons/faTimes';
import { faTrash } from '@fortawesome/pro-duotone-svg-icons/faTrash';
import { faChevronRight } from '@fortawesome/pro-duotone-svg-icons/faChevronRight';
import { faPencil } from '@fortawesome/pro-duotone-svg-icons/faPencil';
import { faDownload } from '@fortawesome/pro-duotone-svg-icons/faDownload';
import { faFilter } from '@fortawesome/pro-duotone-svg-icons/faFilter';
import { faChevronLeft as faSolidChevronLeft} from '@fortawesome/pro-solid-svg-icons/faChevronLeft';
import { faChevronRight as faSolidChevronRight } from '@fortawesome/pro-solid-svg-icons/faChevronRight';
import { faChevronDoubleLeft } from '@fortawesome/pro-duotone-svg-icons/faChevronDoubleLeft';
import { faChevronDoubleRight } from '@fortawesome/pro-duotone-svg-icons/faChevronDoubleRight';
import { faSearch } from '@fortawesome/pro-duotone-svg-icons/faSearch';
import { faArrowDown } from '@fortawesome/pro-duotone-svg-icons/faArrowDown';
import { faMinus } from '@fortawesome/pro-duotone-svg-icons/faMinus';
import { faColumns } from '@fortawesome/pro-duotone-svg-icons/faColumns';

import { MaterialTableProps, default as MaterialTable, Icons } from 'material-table';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * interfaces
 * ---------------------------------------------------------------------------------
 */

export interface ITableProps<Type extends object = any> extends MaterialTableProps<Type> {
    data: Type[];
}

/*
 * ---------------------------------------------------------------------------------
 * Constants
 * ---------------------------------------------------------------------------------
 */
const Icons: Icons = {
    Add: React.forwardRef((props, ref) => <SvgIcon {...props} ref={ref}><FontAwesomeIcon icon={faPlusSquare} size="sm" fixedWidth /></SvgIcon>),
    Check: React.forwardRef((props, ref) => <SvgIcon {...props} ref={ref}><FontAwesomeIcon icon={faCheck} size="sm" fixedWidth /></SvgIcon>),
    Clear: React.forwardRef((props, ref) => <SvgIcon {...props} ref={ref}><FontAwesomeIcon icon={faTimes} size="sm" fixedWidth /></SvgIcon>),
    Delete: React.forwardRef((props, ref) => <SvgIcon {...props} ref={ref}><FontAwesomeIcon icon={faTrash} size="sm" fixedWidth /></SvgIcon>),
    DetailPanel: React.forwardRef((props, ref) => <SvgIcon {...props} ref={ref}><FontAwesomeIcon icon={faChevronRight} size="sm" fixedWidth /></SvgIcon>),
    Edit: React.forwardRef((props, ref) => <SvgIcon {...props} ref={ref}><FontAwesomeIcon icon={faPencil} size="sm" fixedWidth /></SvgIcon>),
    Export: React.forwardRef((props, ref) => <SvgIcon {...props} ref={ref}><FontAwesomeIcon icon={faDownload} size="sm" fixedWidth /></SvgIcon>),
    Filter: React.forwardRef((props, ref) => <SvgIcon {...props} ref={ref}><FontAwesomeIcon icon={faFilter} size="sm" fixedWidth /></SvgIcon>),
    FirstPage: React.forwardRef((props, ref) => <SvgIcon {...props} ref={ref}><FontAwesomeIcon icon={faChevronDoubleLeft} size="sm" fixedWidth /></SvgIcon>),
    LastPage: React.forwardRef((props, ref) => <SvgIcon {...props} ref={ref}><FontAwesomeIcon icon={faChevronDoubleRight} size="sm" fixedWidth /></SvgIcon>),
    NextPage: React.forwardRef((props, ref) => <SvgIcon {...props} ref={ref}><FontAwesomeIcon icon={faSolidChevronRight} size="sm" fixedWidth /></SvgIcon>),
    PreviousPage: React.forwardRef((props, ref) => <SvgIcon {...props} ref={ref}><FontAwesomeIcon icon={faSolidChevronLeft} size="sm" fixedWidth /></SvgIcon>),
    ResetSearch: React.forwardRef((props, ref) => <SvgIcon {...props} ref={ref}><FontAwesomeIcon icon={faTimes} size="sm" fixedWidth /></SvgIcon>),
    Search: React.forwardRef((props, ref) => <SvgIcon {...props} ref={ref}><FontAwesomeIcon icon={faSearch} size="sm" fixedWidth /></SvgIcon>),
    SortArrow: React.forwardRef((props, ref) => <SvgIcon {...props} ref={ref}><FontAwesomeIcon icon={faArrowDown} size="sm" fixedWidth /></SvgIcon>),
    ThirdStateCheck: React.forwardRef((props, ref) => <SvgIcon {...props} ref={ref}><FontAwesomeIcon icon={faMinus} size="sm" fixedWidth /></SvgIcon>),
    ViewColumn: React.forwardRef((props, ref) => <SvgIcon {...props} ref={ref}><FontAwesomeIcon icon={faColumns} size="sm" fixedWidth /></SvgIcon>)
};

const defaultCellStyle: React.CSSProperties = {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
}

const defaultHeaderStyle: React.CSSProperties = {
    fontWeight: 'bold'
}

const Table = <Type extends object>({
    data,
    columns,
    options,
    ...tableProps
}: ITableProps<Type>) => {
    const theme = useTheme();

    const editableData: Type[] = React.useMemo(() => {
        return data?.map(d => ({ ...d }))
    }, [data]);

    const optionsToUse = React.useMemo(() => {
        return {
            rowStyle: (rowData: Type, index: number, level: number) => ({
                background: (index % 2 === 0 ? theme.palette.grey[100] : undefined)
            }),
            ...options,
            headerStyle: {
                ...defaultHeaderStyle,
                ...options?.headerStyle
            }
        }
    }, [options])

    const columnsToUse = React.useMemo(() => {
        return columns.map(column => {
            return {
                ...column,
                cellStyle: {
                    ...defaultCellStyle,
                    ...column.cellStyle
                }
            }
        });
    }, [columns]);

    return (
        <>
            <MaterialTable
                columns={columnsToUse}
                data={editableData}
                icons={Icons}
                options={optionsToUse}
                {...tableProps}
            />
        </>
    );
};

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default Table;
