import { IUseFormErrorBoundaryOptions, useFormErrorBoundary } from '@ngt/forms-core';
import * as Dtos from '../../../api/dtos';

export const errorSubscription = { touched: true };


export const showErrorFn: IUseFormErrorBoundaryOptions['includeError'] = (name, error, { touched }) => touched[name];

export const useFieldErrors =  () => {
    return useFormErrorBoundary<any, Dtos.IValidationError>({
        subscription: errorSubscription,
        includeError: showErrorFn
    });
}