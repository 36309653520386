/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the context for the current form.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to create a context.
 */
import * as React from 'react';
import { IFormContext } from './FormContext';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

export enum ConditionMode {
    Enable,
    Show
}

export interface IConditionViewProps {
    conditionMet: boolean;
    mode: ConditionMode;
}

export interface IConditionConfigurationContext<TValues extends object = any, TError = any> {
    onConditionNotMet?: (names: string[], formActions: IFormContext<TValues, TError>) => void;
    view: React.ComponentType<IConditionViewProps>;
}

/*
 * ---------------------------------------------------------------------------------
 * Constants
 * ---------------------------------------------------------------------------------
 */

export const DefaultConditionView: React.FunctionComponent<IConditionViewProps> = ({
    conditionMet,
    mode,
    children
}) => {
    if (conditionMet || mode !== ConditionMode.Show) {
        return (
            <>
                {children}
            </>
        );
    }

    return (
        null
    );
};

export const DefaultConditionConfigurationContext: IConditionConfigurationContext = {
    onConditionNotMet: (names, formActions) => {
        names?.forEach(name => {
            formActions?.setFieldValue(name, undefined, false, false, false, false, false);
            formActions?.setFieldTouched(name, false, false, false);

            formActions?.notifyFieldChange(name, {
                dirty: false,
                errors: false,
                focused: false,
                initialValue: false,
                touched: true,
                value: true
            });
        })

        formActions?.notifyFormChange({
            dirty: false,
            errors: false,
            fields: false,
            focused: false,
            initialValues: false,
            submitting: false,
            touched: true,
            validating: false,
            values: true
        });
    },
    view: DefaultConditionView
}

const ConditionConfigurationContext = React.createContext<IConditionConfigurationContext>(DefaultConditionConfigurationContext);

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default ConditionConfigurationContext;