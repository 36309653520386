/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the functions to return all property paths within an object.
 * --------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * Import - External
 * --------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * Functions
 * --------------------------------------------------------------------------------
 */

const getParentPropertyPath = (propertyPath?: string | null) => {
    if (!propertyPath || !/(\.|\[.*?\])/.test(propertyPath)) {
        return undefined;
    }

    const match = propertyPath.match(/^.*(?=(\.|\[))/);

    if (match && match[0]) {
        return match[0];
    }

    return undefined;
}


/*
 * --------------------------------------------------------------------------------
 * Default Export
 * --------------------------------------------------------------------------------
 */

export default getParentPropertyPath;
