/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the function used to create the formDefinition reducer.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to create a context.
 */
import * as React from 'react';

/*
 * Used to type the state of a request. 
 */
import { IRequestState, RequestState } from '@ngt/request-utilities';

/*
 * Used to create the reducer and associated actions.
 */
import { ImmerReducer, createReducerFunction, createActionCreators } from 'immer-reducer';

/*
 * Used to create side effects for the reducer.
 */
import { createLogic } from 'redux-logic';

/*
 * Used to type the reducer registry used to register reducers to the store.
 */
import { ReducerRegistry } from '@ngt/reducer-registry-logics';

/*
 * Used to create a typed selector hook.
 */
import { TypedUseSelectorHook, useSelector } from 'react-redux';

/*
 * Used to type the ServiceStack client.
 */
import { JsonServiceClient } from '@servicestack/client';

/*
 * Used to correctly type the created reducer.
 */
import { Reducer } from 'redux';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */


/*
 * Used to get access to backend types.
 */
import * as Dtos from '../../../api/dtos';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */
export interface IIndividualFormDefinitionState {
    formDefinition: Dtos.IFormDefinition | null;
    loadState: IRequestState<Dtos.ResponseStatus>;
}

export interface IFormDefinitionState {
    byId: Record<number, IIndividualFormDefinitionState>;
    byCode: Record<string, IIndividualFormDefinitionState>;
}

export interface IFormDefinitionStore {
    formDefinition: IFormDefinitionState;
}

/*
 * ---------------------------------------------------------------------------------
 * Classes
 * ---------------------------------------------------------------------------------
 */

/**
 * This class is used as a short had to describe all formDefinition reducer state changes and actions. 
 */
export class FormDefinitionReducer extends ImmerReducer<IFormDefinitionState>
{
    public loadById(id: number) {
        if (!this.draftState.byId[id]) {
            this.draftState.byId[id] = { ...initialIndividualFormDefinitionState };
        }

        this.draftState.byId[id].loadState = {
            state: RequestState.Pending
        }
    }

    public loadByIdSuccess(id: number, formDefinition?: Dtos.IFormDefinition) {
        if (!this.draftState.byId[id]) {
            this.draftState.byId[id] = { ...initialIndividualFormDefinitionState };
        }

        this.draftState.byId[id].formDefinition = formDefinition ? formDefinition : null;

        this.draftState.byId[id].loadState = {
            state: RequestState.Success
        };
    }

    public loadByIdFailure(id: number, responseStatus: Dtos.ResponseStatus) {
        if (!this.draftState.byId[id]) {
            this.draftState.byId[id] = { ...initialIndividualFormDefinitionState };
        }

        this.draftState.byId[id].loadState = {
            state: RequestState.Failure,
            responseStatus: responseStatus
        };
    }

    public loadByCode(code: string) {
        if (!this.draftState.byCode[code]) {
            this.draftState.byCode[code] = { ...initialIndividualFormDefinitionState };
        }

        this.draftState.byCode[code].loadState = {
            state: RequestState.Pending
        }
    }

    public loadByCodeSuccess(code: string, formDefinition?: Dtos.IFormDefinition) {
        if (!this.draftState.byCode[code]) {
            this.draftState.byCode[code] = { ...initialIndividualFormDefinitionState };
        }

        this.draftState.byCode[code].formDefinition = formDefinition ? formDefinition : null;

        this.draftState.byCode[code].loadState = {
            state: RequestState.Success
        };
    }

    public loadByCodeFailure(code: string, responseStatus: Dtos.ResponseStatus) {
        if (!this.draftState.byCode[code]) {
            this.draftState.byCode[code] = { ...initialIndividualFormDefinitionState };
        }

        this.draftState.byCode[code].loadState = {
            state: RequestState.Failure,
            responseStatus: responseStatus
        };
    }

    public clearById(id: number) {
        if (this.draftState.byId[id]) {
            delete this.draftState.byId[id];
        }
    }

    public clearByCode(code: string) {
        if (this.draftState.byCode[code]) {
            delete this.draftState.byCode[code];
        }
    }

    public clearAll() {
        this.draftState = { ...initialFormDefinitionState };
    }
}

/*
 * ---------------------------------------------------------------------------------
 * Constants
 * ---------------------------------------------------------------------------------
 */

export const initialIndividualFormDefinitionState: IIndividualFormDefinitionState = {
    formDefinition: null,
    loadState: {
        state: RequestState.None
    }
};

export const initialFormDefinitionState: IFormDefinitionState = {
    byCode: {},
    byId: {}
}

export const formDefinitionActions = createActionCreators(FormDefinitionReducer);
export const formDefinitionReducer = createReducerFunction(FormDefinitionReducer, initialFormDefinitionState);

const createFormDefinitionApi = (client: JsonServiceClient) => ({
    loadById: (id: number) => {
        return client.get(new Dtos.FormDefinitionGetSingleById({ id }));
    },
    loadByCode: (code: string) => {
        return client.get(new Dtos.FormDefinitionGetSingleByCode({ code }));
    }
});

export const createFormDefinitionLogic = (api: ReturnType<typeof createFormDefinitionApi>) => {
    const logics = {
        loadById: createLogic<IFormDefinitionStore, {}, undefined, string, ReturnType<typeof formDefinitionActions.loadById>>({
            type: formDefinitionActions.loadById.type,
            process: async ({ action }, dispatch, done) => {
                try {
                    const response = await api.loadById(action.payload);

                    dispatch(formDefinitionActions.loadByIdSuccess(
                        action.payload,
                        response.formDefinition
                    ));
                }
                catch (error) {
                    dispatch(formDefinitionActions.loadByIdFailure(action.payload, error ? error.responseStatus : undefined));
                }

                done();
            }
        }),
        loadByCode: createLogic<any, {}, undefined, string, ReturnType<typeof formDefinitionActions.loadByCode>>({
            type: formDefinitionActions.loadByCode.type,
            process: async ({ action }, dispatch, done) => {
                try {
                    const response = await api.loadByCode(action.payload);
                    dispatch(formDefinitionActions.loadByCodeSuccess(
                        action.payload,
                        response.formDefinition
                    ));
                }
                catch (error) {
                    dispatch(formDefinitionActions.loadByCodeFailure(action.payload, error ? error.responseStatus : undefined));
                }

                done();
            }
        })
    }

    return [
        logics.loadByCode,
        logics.loadById
    ]
}

export const useFormDefinitionSelector: TypedUseSelectorHook<IFormDefinitionStore> = useSelector;

export const formDefinitionSelectors = {
    formDefinitionById: (state: IFormDefinitionStore, id: number) => state.formDefinition.byId[id]?.formDefinition ?? null,
    loadStateById: (state: IFormDefinitionStore, id: number) => state.formDefinition.byId[id]?.loadState ?? initialIndividualFormDefinitionState.loadState,
    formDefinitionByCode: (state: IFormDefinitionStore, code: string) => state.formDefinition.byCode[code]?.formDefinition ?? null,
    loadStateByCode: (state: IFormDefinitionStore, code: string) => state.formDefinition.byCode[code]?.loadState ?? initialIndividualFormDefinitionState.loadState,
}


/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

const registerFormDefinitionReducer = (client: JsonServiceClient, reducerRegistry: ReducerRegistry) => {
    const api = createFormDefinitionApi(client);

    const logic = createFormDefinitionLogic(api);

    reducerRegistry.register('formDefinition', formDefinitionReducer as Reducer, logic as any);
};

export default registerFormDefinitionReducer;