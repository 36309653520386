/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * This file contains the component that provides context for the online patient
 * management system.
 * ---------------------------------------------------------------------------------
 */

/*
 * ----------------------------------------------------------------------------------
 * Imports - External
 * ----------------------------------------------------------------------------------
 */

/*
 * Required to use React components.
 */
import * as React from 'react';
import { ButtonProps, Button } from '@material-ui/core';


/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import * as Dtos from '../../api/dtos';
import ProgressButton, { IProgressButtonProps } from '../utility/ProgressButton';
import { IFormContext, useFormActions, useFormState } from '@ngt/forms-core';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

/**
 * This interface defines the properties for the SubmitButton component.
 */
export interface ISubmitButtonProps<TValues extends object = any> extends Omit<IProgressButtonProps, 'onClick' | 'type'> {
    onClick?: (event?: React.MouseEvent<HTMLButtonElement, MouseEvent>, formActions?: IFormContext<TValues, Dtos.IValidationError>) => void;
}

/*
 * ---------------------------------------------------------------------------------
 * Components
 * ---------------------------------------------------------------------------------
 */

/**
 * This component provides context for the patient management system.
 * @param param0 component properties.
 */
const SubmitButton = <TValues extends object = any>({
    onClick,
    loading,
    ...buttonProps
}: ISubmitButtonProps<TValues>) => {
    const formActions = useFormActions<TValues, Dtos.IValidationError>();

    const onButtonClick = React.useCallback((event?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        if (onClick) {
            onClick(event, formActions);
        }
    }, [onClick, formActions]);

    const { submitting } = useFormState({ submitting: true });

    return (
        <ProgressButton type="submit" onClick={onButtonClick} loading={loading || submitting}  {...buttonProps} />
    );
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default SubmitButton;