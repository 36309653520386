/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * This file contains the component that provides context for the online patient
 * management system.
 * ---------------------------------------------------------------------------------
 */

/*
 * ----------------------------------------------------------------------------------
 * Imports - External
 * ----------------------------------------------------------------------------------
 */

/*
 * Required to use React components.
 */
import * as React from 'react';


/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import LabelContext from '../../contexts/form/LabelContext';
import Markdown from '../utility/Markdown';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

/**
 * This interface defines the properties for the FormLabel component.
 */
export interface IFormLabelProps {
    label?: string;
    simpleLabel?: boolean;
}

/*
 * ---------------------------------------------------------------------------------
 * Components
 * ---------------------------------------------------------------------------------
 */

/**
 * This component provides context for the patient management system.
 * @param param0 component properties.
 */
const FormLabel: React.FunctionComponent<IFormLabelProps> = ({
    label,
    simpleLabel
}) => {
    const contextLabel = React.useContext(LabelContext);

    const labelToUse = React.useMemo(() => {
        const loadedLabel = simpleLabel ?
            contextLabel?.label ?? contextLabel?.detailedLabel :
            contextLabel?.detailedLabel ?? contextLabel?.label;

        const l = label ?? loadedLabel;

        if (!l) {
            return null;
        }

        return (
            <Markdown>
                {l}
            </Markdown>
        );
    }, [contextLabel?.label, contextLabel?.detailedLabel, label, simpleLabel])


    return (
        labelToUse
    );
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default FormLabel;