/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * This file contains the component that provides context for the online patient
 * management system.
 * ---------------------------------------------------------------------------------
 */

/*
 * ----------------------------------------------------------------------------------
 * Imports - External
 * ----------------------------------------------------------------------------------
 */

/*
 * Required to use React components.
 */
import * as React from 'react';

import FormErrorContext from '../contexts/FormErrorContext';


/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

export enum FormErrorBoundaryMode {
    Propagate,
    Contain
}

/**
 * This interface defines the properties for the Form component.
 */
export interface IFormErrorBoundaryProps {
    mode?: FormErrorBoundaryMode;
    children?: React.ReactNode;
}

/*
 * ---------------------------------------------------------------------------------
 * Components
 * ---------------------------------------------------------------------------------
 */

const FormErrorBoundary: React.FunctionComponent<IFormErrorBoundaryProps> = ({
    mode,
    children
}) => {
    const parentContext = React.useContext(FormErrorContext);

    const [names, setNames] = React.useState<string[]>([]);

    const addName = React.useCallback((name: string) => {
        setNames(currentNames => {
            if (currentNames.includes(name)) {
                return currentNames;
            }

            return [...currentNames, name];
        });
    }, [setNames]);

    const removeName = React.useCallback((name: string) => {
        setNames(currentNames => {
            if (!currentNames.includes(name)) {
                return currentNames;
            }

            return currentNames.filter(n => n !== name);
        });
    }, [setNames]);

    const context = React.useMemo(() => {
        if (parentContext && mode !== FormErrorBoundaryMode.Contain) {
            return undefined;
        }

        return {
            names,
            addName,
            removeName
        }
    }, [parentContext, mode, names, addName, removeName]);

    if (!context) {
        return (
            <>
                {children}
            </>
        );
    }

    return (
        <FormErrorContext.Provider value={context}>
            {children}
        </FormErrorContext.Provider>
    );
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default FormErrorBoundary;