/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the hook to use an institution by id.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to create a context.
 */
import * as React from 'react';

/*
 * Used to type the state of a request. 
 */
import { IRequestState, RequestState } from '@ngt/request-utilities';

/*
 * Used to type actions.
 */
import { ActionCreators } from 'immer-reducer';

/*
 * Used to get access to dispatch to dispatch actions to the store.
 */
import { useDispatch } from 'react-redux';

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to get access to backend types.
 */
import * as Dtos from '../api/dtos';

/*
 * Used to remove the first parameter from the base action creator function type.
 */
import { OmitFirstArg } from '../utilities/OmitArgs';

/*
 * Used to type action creators with first parameter bound to a static. 
 */
import { BoundActionCreator } from '../utilities/BoundActionCreator';

/*
 * Used to bind action creators to the store but keep their assigned type property.
 */
import bindActionCreatorsWithType from '../utilities/bindActionCreatorsWithType';

import { authenticatedUserActions, useAuthenticatedUserSelector, authenticatedUserSelectors } from '../store/modules/authenticatedUser';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

export interface IUseAuthenticatedUserActions {
    checkAuthenticatedUser: BoundActionCreator<typeof authenticatedUserActions.checkAuthenticatedUser>;
    signoutAuthenticatedUser: BoundActionCreator<typeof authenticatedUserActions.signoutAuthenticatedUser>;
    clear: BoundActionCreator<typeof authenticatedUserActions.clear>;
}

/*
 * ---------------------------------------------------------------------------------
 * Functions
 * ---------------------------------------------------------------------------------
 */

const useAuthenticatedUser = <TUser extends Dtos.GetUserResponse>(): [
    TUser | null,
    IRequestState<Dtos.ResponseStatus>,
    IUseAuthenticatedUserActions
] => {
    const dispatch = useDispatch();

    const actions: IUseAuthenticatedUserActions = React.useMemo(() => {
        return bindActionCreatorsWithType({
            checkAuthenticatedUser: authenticatedUserActions.checkAuthenticatedUser,
            signoutAuthenticatedUser: authenticatedUserActions.signoutAuthenticatedUser,
            clear: authenticatedUserActions.clear
        }, dispatch);
    }, [authenticatedUserActions, dispatch]);

    const authenticatedUser = useAuthenticatedUserSelector(authenticatedUserSelectors.selectUser);

    const loadState = useAuthenticatedUserSelector(authenticatedUserSelectors.selectLoadState);

    return [
        authenticatedUser as TUser | null,
        loadState,
        actions
    ];
};

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default useAuthenticatedUser;