/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 *  This file contains the functions to return a camel case string from a pascal
 * cased string.
 * --------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * Import - External
 * --------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * Functions
 * --------------------------------------------------------------------------------
 */

export const pascalToCamelCase = (text: string): string => {
    if (!text) {
        return '';
    }

    return text.charAt(0).toLowerCase() + text.slice(1)
};

export const pascalToCameCasePropertyPath = (text: string) => {
    if (!text) {
        return '';
    }

    const splitFieldConst = text.split('.');

    return splitFieldConst.map(s => pascalToCamelCase(s)).join('.');
};


/*
 * --------------------------------------------------------------------------------
 * Default Export
 * --------------------------------------------------------------------------------
 */

export default pascalToCamelCase;
