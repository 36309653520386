/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains types to help create and mutate function types.
 * --------------------------------------------------------------------------------
 */

/*
* ---------------------------------------------------------------------------------
* Imports - External
* ---------------------------------------------------------------------------------
*/

import * as React from 'react';

/**
 * typings
 */
import FormsContext from '../../contexts/FormsContext';
import FormDefinitionContext from '../../contexts/configuration/FormDefinitionContext';
import FormContext from '../../contexts/data/FormContext';
import { IFormLabel } from '../../contexts/form/LabelsContext';
import { pascalToCameCasePropertyPath } from '../../utilities/pascalToCamelCase';
import LookupsContext from '../../contexts/utility/LookupsContext';
import { IFormState } from '@ngt/forms-core';
import IDtoRequestClass from '../../utilities/IDtoRequestClass';
import * as Dtos from '../../api/dtos';
import asyncDebounce from '../../utilities/asyncDebounce';
import { useHistory, useParams } from 'react-router-dom';
import IDtoClass from '../../utilities/IDtoClass';
import { IFormContext } from '@ngt/forms-core';
import useSnackbar, { SnackbarVariant } from '../useSnackbar';
const pluralize = require('pluralize');
import AlertTitle from '@material-ui/lab/AlertTitle';
import useAuthenticatedUser from '../useAuthenticatedUser';

/*
* ---------------------------------------------------------------------------------
* Imports - Internal
* ---------------------------------------------------------------------------------
*/


/*
* ---------------------------------------------------------------------------------
* Interfaces
* ---------------------------------------------------------------------------------
*/


interface IFormPostValidateWithCodes<Type extends Dtos.IForm> {
    form?: Type;
}

interface IFormValidationResponse {
    responseStatus?: Dtos.ResponseStatus;
    validationResult?: Dtos.IFormValidationResult;
}

export interface IUseFormOptions<Type extends Dtos.IForm> {
    formType: IDtoClass<Type>;
    afterFormSave?: (form?: Type | null) => void;
    afterFormSaveAndReturn?: (form?: Type | null) => void;
    onCancel?: () => void;
    readOnly?: boolean;
}

const errorVariantMapping: Record<Dtos.ValidationErrorType, SnackbarVariant> = {
    [Dtos.ValidationErrorType.Warning]: 'warning',
    [Dtos.ValidationErrorType.Ineligible]: 'ineligible',
    [Dtos.ValidationErrorType.Normal]: 'error',
    [Dtos.ValidationErrorType.StratificationFailure]: 'stratification-failure',
    [Dtos.ValidationErrorType.Critical]: 'critical'
}

const errorTextMapping: Record<Dtos.ValidationErrorType, string> = {
    [Dtos.ValidationErrorType.Warning]: 'warning',
    [Dtos.ValidationErrorType.Ineligible]: 'ineligibility warning',
    [Dtos.ValidationErrorType.Normal]: 'error',
    [Dtos.ValidationErrorType.StratificationFailure]: 'stratification failure',
    [Dtos.ValidationErrorType.Critical]: 'critical error'
}

/*
* ---------------------------------------------------------------------------------
* Functions
* ---------------------------------------------------------------------------------
*/

const useForm = <Type extends Dtos.IForm>({
    formType,
    afterFormSave,
    afterFormSaveAndReturn,
    onCancel,
    readOnly
}: IUseFormOptions<Type>) => {
    // Get form name from the DTO class.

    const [user] = useAuthenticatedUser();

    const { enqueueSnackbar } = useSnackbar();

    const { formDefinition } = React.useContext(FormDefinitionContext);

    const formDefinitionCode = React.useMemo(() => {
        return formDefinition?.code;
    }, [formDefinition])

    // Pull in Forms Context to get form meta data and DTO collection.
    const formsContext = React.useContext(FormsContext);

    // Get formDefinitionId using form name and form meta data.
    const formName = React.useMemo(() => {
        return formsContext.formMetadata?.find(fm => fm.formDefinitionCode === formDefinitionCode)?.name;
    }, [formDefinitionCode, formsContext.formMetadata]);

    // If form definition ID is not found in meta data this means that the form is not registered in the Forms.
    if (!formName) {
        throw new Error('Form is not registered in Forms.')
    }

    // get form definition from context.
    const { form, actions: formActions } = React.useContext(FormContext)

    const formLabels = React.useMemo(() => {
        const labels: IFormLabel[] = [];

        formDefinition?.properties?.forEach(p => labels.push({
            name: pascalToCameCasePropertyPath(p.propertyName),
            label: p.label,
            detailedLabel: p.detailedLabel
        }));

        if (formDefinition?.subformProperties) {
            Object.keys(formDefinition.subformProperties).forEach(key => {
                formDefinition.subformProperties[key].forEach(p => labels.push({
                    name: pascalToCameCasePropertyPath(`${key}.${p.propertyName}`),
                    label: p.label,
                    detailedLabel: p.detailedLabel
                }));
            })
        }

        return labels;
    }, [pascalToCameCasePropertyPath, formDefinition])



    // get lookups for form.
    const { lookups } = React.useContext(LookupsContext)

    // swap lookup property paths to camel case from pascal case.
    const processedLookups = React.useMemo(() => {
        return lookups?.map(lookup => ({ ...lookup, propertyName: pascalToCameCasePropertyPath(lookup.propertyName) }))
    }, [lookups, pascalToCameCasePropertyPath]);

    // Find the validate Request DTO and create a validate method for the form.
    const onValidate = React.useCallback(async (formState: IFormState<Type, Dtos.IValidationError>) => {
        // find the validate method based on form name and static suffix.
        const FormPostValidate: IDtoRequestClass<IFormPostValidateWithCodes<Type>, IFormValidationResponse> = formsContext.dtos[`${formName}PostValidate`];

        // Send request to server.
        const response = await formsContext
            .serviceStackClient
            .post(new FormPostValidate({ form: formState.values }));

        // parse errors into a format the form understands.
        const groupErrors = response.validationResult?.errors?.reduce((a: Record<string, Dtos.IFormValidationError[]>, b: Dtos.IFormValidationError) => {
            const propertyName = pascalToCameCasePropertyPath(b.property)

            if (!a[propertyName]) {
                a[propertyName] = [];
            }

            a[propertyName].push(b);

            return a;
        }, {}) ?? {};

        // return errors;
        return groupErrors;

    }, [formsContext.dtos, formsContext.serviceStackClient, formName, pascalToCameCasePropertyPath, event]);

    // debounce validation functions to reduce calls to the server and associate lag.
    const debouncedValidate = React.useMemo(() => {
        return asyncDebounce(onValidate, 500);
    }, [onValidate]);

    // override the allow submit function so that submit is only blocked when there is a critical error.
    const allowSubmit = undefined;
    //const allowSubmit = React.useCallback(async ({ errors }: IFormState<Type, Dtos.IValidationError>) => {
    //    if (!errors) {
    //        return true;
    //    }

    //    return !Object.keys(errors).some(key => errors[key] && errors[key].some(e => e.type === Dtos.ValidationErrorType.Critical));
    //}, []);

    const history = useHistory();
    const params = useParams<Record<string, string>>();

    const onFormSubmitValidationFailure = React.useCallback(async ({ errors }: IFormState<Type, Dtos.IValidationError>, validationError: boolean) => {
        if (validationError) {
            enqueueSnackbar(
                <>
                    <AlertTitle>
                        Form Not Saved
                    </AlertTitle>
                    An error occurred while attempting to validate the form.
                </>,
                { variant: 'critical' }
            );
        }
        else {
            const criticalErrors = Object
                .keys(errors)
                .reduce((array: Dtos.IValidationError[], key: string) => {
                    const propertyErrors = errors[key]?.reduce((propertyArray: Dtos.IValidationError[], e: Dtos.IValidationError) => {
                        if (e.type === Dtos.ValidationErrorType.Critical) {
                            return [...propertyArray, e]
                        }

                        return propertyArray;
                    }, [])

                    return [...array, ...propertyErrors]
                }, []);

            enqueueSnackbar(
                <>
                    <AlertTitle>
                        Form Not Saved
                    </AlertTitle>
                    Please correct the {criticalErrors.length} blocking {pluralize('error', criticalErrors.length)} and submit the form again.
                </>,
                { variant: 'critical' }
            );
        }
    }, [enqueueSnackbar]);

    const onFormSubmitFailure = React.useCallback(async (formState: IFormState<Type, Dtos.IValidationError>) => {
        enqueueSnackbar(
            <>
                <AlertTitle>
                    Form Not Saved
                    </AlertTitle>
                    An error occurred while attempting to save the form.
            </>,
            { variant: 'critical' }
        );
    }, []);

    const handleSubmit = React.useCallback(async ({ values, errors }: IFormState<Type, Dtos.IValidationError>) => {
        const { submitType, ...form } = values as any;

        const result = await formActions.asyncSave(values);

        const allErrors = Object
            .keys(errors)
            .reduce((array: Dtos.IValidationError[], key: string) => {
                const propertyErrors = errors[key]?.reduce((propertyArray: Dtos.IValidationError[], e: Dtos.IValidationError) => {
                    return [...propertyArray, e]
                }, [])

                return [...array, ...propertyErrors]
            }, []);

        const maxErrorType = allErrors.reduce((maxError: Dtos.ValidationErrorType | undefined, error) => (error.type ?? 0) > (maxError ?? 0) ? error.type : maxError, undefined);

        if (maxErrorType) {

            const scopedErrors = allErrors.filter(e => e.type === maxErrorType);

            enqueueSnackbar(
                <>
                    <AlertTitle>
                        Form Saved
                    </AlertTitle>
                    The form was successfully saved but contained {scopedErrors.length} {pluralize(errorTextMapping[maxErrorType], scopedErrors.length)}.
                </>,
                { variant: errorVariantMapping[maxErrorType] }
            );
        }
        else {
            enqueueSnackbar(
                <>
                    <AlertTitle>
                        Form Saved
                    </AlertTitle>
                    The form was successfully saved.
                </>,
                { variant: 'success' }
            );
        }

        if (submitType === 'save') {
            if (afterFormSave) {
                afterFormSave(result as Type);
            }
        }
        else {
            if (afterFormSaveAndReturn) {
                afterFormSaveAndReturn(result as Type);
            }
        }
    }, [formActions.asyncSave, afterFormSave, afterFormSaveAndReturn, formsContext.serviceStackClient, history, params, formsContext.routeParameters, user])

    const onFormSubmit = React.useCallback((event?: React.MouseEvent<HTMLButtonElement, MouseEvent>, formActions?: IFormContext<Type, Dtos.IValidationError>) => {
        if (!formActions?.getSubmitting()) {
            formActions?.setFieldValue('submitType', 'save');
        }
    }, [])

    const onFormSubmitAndReturn = React.useCallback((event?: React.MouseEvent<HTMLButtonElement, MouseEvent>, formActions?: IFormContext<Type, Dtos.IValidationError>) => {
        if (!formActions?.getSubmitting()) {
            formActions?.setFieldValue('submitType', 'saveAndReturn');
        }
    }, [])

    const onFormCancel = React.useCallback(() => {
        if (onCancel) {
            onCancel();
        }
    }, [history, params, formsContext.routeParameters, onCancel, user]);

    const formReadOnly = React.useMemo(() => {
        if (readOnly === true) {
            return true;
        }

        return false;

    }, [readOnly, formDefinition, form])

    return {
        form: form as Type | null,
        formDefinition,
        formName: formDefinition?.name,
        labels: formLabels,
        lookups: processedLookups,
        handleSubmit,
        allowSubmit,
        validate: debouncedValidate,
        onFormSubmitAndReturn,
        onFormSubmit,
        onFormCancel,
        onFormSubmitFailure,
        onFormSubmitValidationFailure,
        readOnly: formReadOnly
    }
};

/*
* ---------------------------------------------------------------------------------
* Default Exports
* ---------------------------------------------------------------------------------
*/
export default useForm;

