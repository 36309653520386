/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the function used to create the forms reducer.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to create a context.
 */
import * as React from 'react';

/*
 * Used to type the state of a request. 
 */
import { IRequestState, RequestState } from '@ngt/request-utilities';

/*
 * Used to create the reducer and associated actions.
 */
import { ImmerReducer, createReducerFunction, createActionCreators, ActionCreators } from 'immer-reducer';

/*
 * Used to create side effects for the reducer.
 */
import { createLogic } from 'redux-logic';

/*
 * Used to type the reducer registry used to register reducers to the store.
 */
import { ReducerRegistry } from '@ngt/reducer-registry-logics';

/*
 * Used to create a typed selector hook.
 */
import { TypedUseSelectorHook, useSelector } from 'react-redux';

/*
 * Used to type the ServiceStack client.
 */
import { JsonServiceClient } from '@servicestack/client';

/*
 * Used to correctly type the created reducer.
 */
import { Reducer } from 'redux';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */


/*
 * Used to get access to backend types.
 */
import * as Dtos from '../../../api/dtos';

/*
 * Used to type special reducer registry.
 */
import ActionReducerRegistry from '../../../utilities/ActionRegistryReducer';

/*
 * Used to type Dto classes.
 */
import IDtoRequestClass from '../../../utilities/IDtoRequestClass';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

export interface IIndividualFormsState {
    forms: Dtos.IForm[] | null;
    loadState: IRequestState<Dtos.ResponseStatus>;
}

export interface IFormsState {
    allForms: Record<string, IIndividualFormsState>;
}

export interface IFormsStore {
    forms: Record<string, IFormsState>;
}

interface IFormCollectionResponse {
    forms?: Dtos.IForm[];
    responseStatus?: Dtos.ResponseStatus
}

/*
 * ---------------------------------------------------------------------------------
 * Helper Fuctions
 * ---------------------------------------------------------------------------------
 */

const allFormsContext = 'allForms';


/*
 * ---------------------------------------------------------------------------------
 * Classes
 * ---------------------------------------------------------------------------------
 */

/**
 * This class is used as a short had to describe all forms reducer state changes and actions. 
 */
export class FormsReducer extends ImmerReducer<IFormsState>
{
    public load() {

        if (!this.draftState.allForms[allFormsContext]) {
            this.draftState.allForms[allFormsContext] = { ...initialIndividualFormsState };
        }

        this.draftState.allForms[allFormsContext].loadState = {
            state: RequestState.Pending
        };
    }

    public loadSuccess(forms?: Dtos.IForm[]) {

        if (!this.draftState.allForms[allFormsContext]) {
            this.draftState.allForms[allFormsContext] = { ...initialIndividualFormsState };
        }

        this.draftState.allForms[allFormsContext].forms = forms ? forms : null;

        this.draftState.allForms[allFormsContext].loadState = {
            state: RequestState.Success
        };
    }

    public loadFailure(responseStatus?: Dtos.ResponseStatus) {

        if (!this.draftState.allForms[allFormsContext]) {
            this.draftState.allForms[allFormsContext] = { ...initialIndividualFormsState };
        }

        this.draftState.allForms[allFormsContext].loadState = {
            state: RequestState.Failure,
            responseStatus: responseStatus
        };
    }

    public clearAll() {
        this.draftState = { ...initialFormsState };
    }
}

/*
 * ---------------------------------------------------------------------------------
 * Constants
 * ---------------------------------------------------------------------------------
 */

export const initialFormsState: IFormsState = {
    allForms: {}
}

export const initialIndividualFormsState: IIndividualFormsState = {
    forms: null,
    loadState: {
        state: RequestState.None
    }
}

const createNamedFormsReducer = (formMetadata: Dtos.FormMetadata) => {
    let NamedReducer = class extends FormsReducer { };

    Object.defineProperty(NamedReducer, 'name', { value: `${formMetadata.name}FormsReducer` });

    return NamedReducer;
};

const createFormsApi = (formMetadata: Dtos.FormMetadata, dtos: Record<string, any>, client: JsonServiceClient) => {
    const FormGetCollection: IDtoRequestClass<Dtos.IFormGetCollection, IFormCollectionResponse> = dtos[`${formMetadata.name}GetCollection`];

    return {
        load: () => {
            return client.get(new FormGetCollection());
        }
    }
};

const createFormsLogic = (actions: ActionCreators<typeof FormsReducer>, api: ReturnType<typeof createFormsApi>) => {
    const logic = {
        load: createLogic<IFormsStore, {}, undefined, string, ReturnType<typeof actions.load>>({
            type: actions.load.type,
            process: async ({ action }, dispatch, done) => {
                
                try {
                    const response = await api.load();

                    dispatch(actions.loadSuccess(
                        response.forms
                    ));
                }
                catch (error) {
                    dispatch(actions.loadFailure(error ? error.responseStatus : undefined));
                }

                done();
            }
        })
    }

    return [
        logic.load
    ]
};

export const useFormsSelector: TypedUseSelectorHook<IFormsStore> = useSelector;

export const formsSelectors = {
    forms: (state: IFormsStore, formPropertyName: string) => {
        
        if (!state.forms[formPropertyName]?.allForms[allFormsContext]) {
            return initialIndividualFormsState.forms;
        }

        return state.forms[formPropertyName].allForms[allFormsContext].forms;
    },
    loadState: (state: IFormsStore, formPropertyName: string) => {

        if (!state.forms[formPropertyName]?.allForms[allFormsContext]) {
            return initialIndividualFormsState.loadState;
        }

        return state.forms[formPropertyName].allForms[allFormsContext].loadState;
    }
}


/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

const registerFormsReducer = (formMetadata: Dtos.FormMetadata, dtos: Record<string, any>, client: JsonServiceClient, reducerRegistry: ActionReducerRegistry) => {
    if (!formMetadata?.propertyName) {
        return;
    }

    const NamedReducer = createNamedFormsReducer(formMetadata);

    const actions = createActionCreators(NamedReducer);
    const reducer = createReducerFunction(NamedReducer, initialFormsState);

    const api = createFormsApi(formMetadata, dtos, client);

    const logic = createFormsLogic(actions, api);

    reducerRegistry.register(formMetadata.propertyName, reducer as Reducer, logic as any);
    reducerRegistry.registerActions(formMetadata.propertyName, actions);
};

export default registerFormsReducer;