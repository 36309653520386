/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the hook used to initialise a form..
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to create a context.
 */
import * as React from 'react';
import useFormState from './useFormState'
import FormErrorContext from '../contexts/FormErrorContext';
import { IFormState, IFormSubscription } from '../FormManager';

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

export type ShowErrorFunction<TValues extends object = any, TError = any> = (name: string, error: TError, formState: IFormState<TValues, TError>) => boolean;

export interface IUseFormErrorBoundaryOptions<TValues extends object = any, TError = any> {
    includeError?: ShowErrorFunction<TValues, TError>;
    subscription?: Partial<Omit<IFormSubscription, 'errors'>>;
}

/*
 * ---------------------------------------------------------------------------------
 * Functions
 * ---------------------------------------------------------------------------------
 */

const useFormErrorBoundary = <TValues extends object = any, TError = any>(options?: IUseFormErrorBoundaryOptions<TValues, TError>) => {
    const formErrorContext = React.useContext(FormErrorContext);
    const subscription = React.useMemo(() => {
        return { ...options?.subscription, errors: true };
    }, [options?.subscription]);

    const formState = useFormState<TValues, TError>(subscription);

    const scopedErrors = React.useMemo(() => {
        if (!formErrorContext?.names || !formState.errors) {
            return null;
        }

        return formErrorContext.names.reduce((a: TError[], b: string) => {
            if (formState.errors[b] && formState.errors[b].length > 0) {
                if (options?.includeError) {
                    return [...a, ...formState.errors[b].filter(error => options!.includeError!(b, error, formState))]
                }
                else {
                    return [...a, ...formState.errors[b]]
                }
            }

            return a;
        }, [])
    }, [formErrorContext?.names, formState, options?.includeError]);

    return scopedErrors;
};

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default useFormErrorBoundary;