/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the hook used to get the form context.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to create a context.
 */
import * as React from 'react';

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import FormContext, { IFormContext } from '../contexts/FormContext';

import { IFieldSubscription, IFieldSubscriber, IUnsubscribe } from '../FormManager';
import ConditionContext from '../contexts/ConditionContext';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

export interface IUseFieldReturn<TValue = any, TError = any> {
    getValue: () => TValue | null | undefined;
    getTouched: () => boolean;
    getDirty: () => boolean;
    getFocused: () => boolean;
    getErrors: () => TError[];
    getInitialValue: () => TValue | null | undefined;
    setValue: (value: TValue | null | undefined, silent?: boolean, notifyForm?: boolean, notifyField?: boolean) => boolean;
    setTouched: (touched: boolean, notifyForm?: boolean, notifyField?: boolean) => boolean;
    setDirty: (dirty: boolean, notifyForm?: boolean, notifyField?: boolean) => boolean;
    setFocused: (focused: boolean, notifyForm?: boolean, notifyField?: boolean) => boolean;
    setErrors: (errors: TError[] | null, notifyForm?: boolean, notifyField?: boolean) => boolean;
    subscribe: (subscriber: IFieldSubscriber<TValue, TError>, subscription?: IFieldSubscription) => IUnsubscribe;
    register: (notifyForm?: boolean) => boolean;
    unregister: (notifyForm?: boolean) => boolean;
}

/*
 * ---------------------------------------------------------------------------------
 * Functions
 * ---------------------------------------------------------------------------------
 */

const useField = <TValue = any, TError = any>(path: string, autoRegister?: boolean) => {
    const context: IFormContext<any, TError> | null = React.useContext(FormContext);

    if (context === null) {
        throw new Error("Form Context does not exist");
    }

    const conditionContext = React.useContext(ConditionContext);

    const fieldActions: IUseFieldReturn<TValue, TError> = React.useMemo(() => {
        return {
            getValue: () => context.getFieldValue(path),
            getTouched: () => context.getFieldTouched(path),
            getDirty: () => context.getFieldDirty(path),
            getFocused: () => context.getFieldFocused(path),
            getErrors: () => context.getFieldErrors(path),
            getInitialValue: () => context.getFieldInitialValue(path),
            setValue: (value: TValue | null | undefined, updateDirty?: boolean, updateTouched?: boolean, updateFocused?: boolean, notifyForm?: boolean, notifyField?: boolean) => context.setFieldValue(path, value, updateDirty, updateTouched, updateFocused, notifyForm, notifyField),
            setTouched: (touched: boolean, notifyForm?: boolean, notifyField?: boolean) => context.setFieldTouched(path, touched, notifyForm, notifyField),
            setDirty: (dirty: boolean, notifyForm?: boolean, notifyField?: boolean) => context.setFieldDirty(path, dirty, notifyForm, notifyField),
            setFocused: (focused: boolean, notifyForm?: boolean, notifyField?: boolean) => context.setFieldFocused(path, focused, notifyForm, notifyField),
            setErrors: (errors: TError[] | null, notifyForm?: boolean, notifyField?: boolean) => context.setFieldErrors(path, errors, notifyForm, notifyField),
            subscribe: (subscriber: IFieldSubscriber<TValue, TError>, subscription?: IFieldSubscription) => context.subscribeToField(path, subscriber, subscription),
            register: (notifyForm?: boolean) => {
                conditionContext?.addName(path);

                return context.registerField(path, notifyForm);
            },
            unregister: (notifyForm?: boolean) => {
                conditionContext?.removeName(path);

                return context.unregisterField(path, notifyForm);
            }
        }
    }, [context, path, conditionContext?.addName, conditionContext?.removeName]);

    React.useEffect(() => {
        if (autoRegister === false) {
            return () => { };
        }

        fieldActions.register()

        return () => {
            fieldActions.unregister();
        }
    }, [fieldActions.register, fieldActions.unregister, autoRegister]);

    return fieldActions;
};

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default useField;