/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the function used to create the form reducer.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to create a context.
 */
import * as React from 'react';

/*
 * Used to type the state of a request. 
 */
import { IRequestState, RequestState } from '@ngt/request-utilities';

/*
 * Used to create the reducer and associated actions.
 */
import { ImmerReducer, createReducerFunction, createActionCreators, ActionCreators } from 'immer-reducer';

/*
 * Used to create side effects for the reducer.
 */
import { createLogic } from 'redux-logic';

/*
 * Used to type the reducer registry used to register reducers to the store.
 */
import { ReducerRegistry } from '@ngt/reducer-registry-logics';

/*
 * Used to create a typed selector hook.
 */
import { TypedUseSelectorHook, useSelector } from 'react-redux';

/*
 * Used to type the ServiceStack client.
 */
import { JsonServiceClient } from '@servicestack/client';

/*
 * Used to correctly type the created reducer.
 */
import { Reducer } from 'redux';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */


/*
 * Used to get access to backend types.
 */
import * as Dtos from '../../../api/dtos';

/*
 * Used to type special reducer registry.
 */
import ActionReducerRegistry from '../../../utilities/ActionRegistryReducer';

/*
 * Used to type Dto classes.
 */
import IDtoRequestClass from '../../../utilities/IDtoRequestClass';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */
export interface IIndividualFormState {
    form: Dtos.IForm | null;
    loadState: IRequestState<Dtos.ResponseStatus>;
    saveState: IRequestState<Dtos.ResponseStatus>;
}

export interface IFormState {
    byId: Record<string, IIndividualFormState>;
}

export interface IFormStore {
    form: Record<string, IFormState>;
}

interface IFormSingleResponse {
    form?: Dtos.IForm;
    responseStatus?: Dtos.ResponseStatus;
}

interface IFormPostSave {
    form?: Dtos.IForm;
}

interface IFormPostSaveWithIds {
    form?: Dtos.IForm;
}

interface IFormPostSaveWithCodes {
    form?: Dtos.IForm;
}

/*
 * ---------------------------------------------------------------------------------
 * Helper Fuctions
 * ---------------------------------------------------------------------------------
 */

const createIdsContext = (formId: number | null) => {
    return `${(formId ?? 'new')}`;
}

/*
 * ---------------------------------------------------------------------------------
 * Classes
 * ---------------------------------------------------------------------------------
 */

/**
 * This class is used as a short had to describe all form reducer state changes and actions. 
 */
export class FormReducer extends ImmerReducer<IFormState>
{
    public loadById(id: number | null, allowCreate?: boolean) {

        const contextId = createIdsContext(id);

        if (!this.draftState.byId[contextId]) {
            this.draftState.byId[contextId] = { ...initialIndividualFormState };
        }

        this.draftState.byId[contextId].loadState = {
            state: RequestState.Pending
        }
    }

    public loadByIdSuccess(id: number | null, form?: Dtos.IForm) {

        const contextId = createIdsContext(id);

        if (!this.draftState.byId[contextId]) {
            this.draftState.byId[contextId] = { ...initialIndividualFormState };
        }

        this.draftState.byId[contextId].form = form ? form : null;

        this.draftState.byId[contextId].loadState = {
            state: RequestState.Success
        };
    }

    public loadByIdFailure(id: number | null, responseStatus: Dtos.ResponseStatus) {

        const contextId = createIdsContext(id);

        if (!this.draftState.byId[contextId]) {
            this.draftState.byId[contextId] = { ...initialIndividualFormState };
        }

        this.draftState.byId[contextId].loadState = {
            state: RequestState.Failure,
            responseStatus: responseStatus
        };
    }

    public saveById(id: number | null, form?: Dtos.IForm) {

        const contextId = createIdsContext(id);

        if (!this.draftState.byId[contextId]) {
            this.draftState.byId[contextId] = { ...initialIndividualFormState };
        }

        this.draftState.byId[contextId].saveState = {
            state: RequestState.Pending
        }
    }

    public saveByIdSuccess(id: number | null, form?: Dtos.IForm) {

        const contextId = createIdsContext(id);

        if (!this.draftState.byId[contextId]) {
            this.draftState.byId[contextId] = { ...initialIndividualFormState };
        }

        this.draftState.byId[contextId].form = form ? form : null;

        this.draftState.byId[contextId].saveState = {
            state: RequestState.Success
        };
    }

    public saveByIdFailure(id: number | null, responseStatus: Dtos.ResponseStatus) {

        const contextId = createIdsContext(id);

        if (!this.draftState.byId[contextId]) {
            this.draftState.byId[contextId] = { ...initialIndividualFormState };
        }

        this.draftState.byId[contextId].saveState = {
            state: RequestState.Failure,
            responseStatus: responseStatus
        };
    }

    public clearById(id: number | null) {

        const contextId = createIdsContext(id);

        if (this.draftState.byId[contextId]) {
            delete this.draftState.byId[contextId];
        }
    }

    public clearAll() {
        this.draftState = { ...initialFormState };
    }
}

/*
 * ---------------------------------------------------------------------------------
 * Constants
 * ---------------------------------------------------------------------------------
 */

export const initialIndividualFormState: IIndividualFormState = {
    form: null,
    loadState: {
        state: RequestState.None
    },
    saveState: {
        state: RequestState.None
    }
};

export const initialFormState: IFormState = {
    byId: {}
}

const createNamedFormReducer = (formMetadata: Dtos.FormMetadata) => {
    let NamedReducer = class extends FormReducer { };

    Object.defineProperty(NamedReducer, 'name', { value: `${formMetadata.name}FormReducer` });

    return NamedReducer;
};

const createEventApi = (formMetadata: Dtos.FormMetadata, dtos: Record<string, any>, client: JsonServiceClient) => {
    const FormGetSingleById: IDtoRequestClass<Dtos.IFormGetSingleById, IFormSingleResponse> = dtos[`${formMetadata.name}GetSingleById`];

    const FormPostSave: IDtoRequestClass<IFormPostSave, IFormSingleResponse> = dtos[`${formMetadata.name}PostSave`];

    return {
        loadById: (id?: number | null, allowCreate?: boolean) => {
            return client.get(new FormGetSingleById({ id: id ?? undefined, allowCreate: allowCreate }));
        },
        save: (form?: Dtos.IForm) => {
            return client.post(new FormPostSave({ form }));
        }
    }
};

export const createEventLogic = (actions: ActionCreators<typeof FormReducer>, api: ReturnType<typeof createEventApi>) => {
    const logics = {
        loadById: createLogic<IFormStore, {}, undefined, string, ReturnType<typeof actions.loadById>>({
            type: actions.loadById.type,
            process: async ({ action }, dispatch, done) => {

                const [id, allowCreate] = action.payload as any;

                try {
                    const response = await api.loadById(id, allowCreate);

                    dispatch(actions.loadByIdSuccess(
                        id,
                        response.form
                    ) as any);
                }
                catch (error) {
                    dispatch(actions.loadByIdFailure(id, error ? error.responseStatus : undefined));
                }

                done();
            }
        }),
        saveById: createLogic<IFormStore, {}, undefined, string, ReturnType<typeof actions.saveById>>({
            type: actions.saveById.type,
            process: async ({ action }, dispatch, done) => {
                const [id, form] = action.payload as any;
                try {
                    const response = await api.save(form);

                    dispatch(actions.saveByIdSuccess(
                        id,
                        response.form
                    ) as any);
                }
                catch (error) {
                    dispatch(actions.saveByIdFailure(id, error ? error.responseStatus : undefined));
                }

                done();
            }
        })
    }

    return [
        logics.loadById,
        logics.saveById
    ]
}

export const useFormSelector: TypedUseSelectorHook<IFormStore> = useSelector;

export const formSelectors = {
    formById: (state: IFormStore, formPropertyName: string, id: number | null) => state.form[formPropertyName]?.byId[createIdsContext(id)]?.form ?? initialIndividualFormState.form,
    loadStateById: (state: IFormStore, formPropertyName: string, id: number | null) => state.form[formPropertyName]?.byId[createIdsContext(id)]?.loadState ?? initialIndividualFormState.loadState,
    saveStateById: (state: IFormStore, formPropertyName: string, id: number | null) => state.form[formPropertyName]?.byId[createIdsContext(id)]?.saveState ?? initialIndividualFormState.saveState
}


/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

const registerFormReducer = (formMetadata: Dtos.FormMetadata, dtos: Record<string, any>, client: JsonServiceClient, reducerRegistry: ActionReducerRegistry) => {
    if (!formMetadata?.propertyName) {
        return;
    }

    const NamedReducer = createNamedFormReducer(formMetadata);

    const actions = createActionCreators(NamedReducer);
    const reducer = createReducerFunction(NamedReducer, initialFormState);

    const api = createEventApi(formMetadata, dtos, client);

    const logic = createEventLogic(actions, api);

    reducerRegistry.register(formMetadata.propertyName, reducer as Reducer, logic as any);
    reducerRegistry.registerActions(formMetadata.propertyName, actions);
};

export default registerFormReducer;