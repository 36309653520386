/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the functions to return all property paths within an object.
 * --------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * Import - External
 * --------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * Functions
 * --------------------------------------------------------------------------------
 */

//https://stackoverflow.com/questions/42674473/get-all-keys-of-a-deep-object-in-javascript
const getDeepKeys = <T extends object = any>(obj: T) => {
    let keys: string[] = [];

    for (let key in obj) {
        const value: any = obj[key];

        if (typeof obj[key] === "object" && !Array.isArray(obj[key])) {
            const subkeys = getDeepKeys(value);
            keys = keys.concat(subkeys.map(function (subkey) {
                return key + "." + subkey;
            }));
        } else if (Array.isArray(value)) {
            for (let i = 0; i < value.length; i++) {
                const subkeys = getDeepKeys(value[i]);

                if (subkeys.length === 0) {
                    keys = keys.concat([ key + "[" + i + "]"]);
                }
                else {
                    keys = keys.concat(subkeys.map(function (subkey) {
                        return key + "[" + i + "]" + "." + subkey;
                    }));
                }
            }
        } else {
            keys.push(key);
        }
    }
    return keys;
}


/*
 * --------------------------------------------------------------------------------
 * Default Export
 * --------------------------------------------------------------------------------
 */

export default getDeepKeys;
