/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * This file contains the component that provides context for the online patient
 * management system.
 * ---------------------------------------------------------------------------------
 */

/*
 * ----------------------------------------------------------------------------------
 * Imports - External
 * ----------------------------------------------------------------------------------
 */

/*
 * Required to use React components.
 */
import * as React from 'react';

import { Column } from 'material-table';

import { Paper } from '@material-ui/core';
/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import FormLookupsContext from '../../../contexts/form/FormLookupsContext';

import * as Dtos from '../../../api/dtos';

import CollapsibleTable from '../../table/CollapsibleTable';

import Table from '../../table/Table';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

/**
 * This interface defines the properties for the FormValues component.
 */
export interface IFormLookupsProps {

}

/*
 * ---------------------------------------------------------------------------------
 * Components
 * ---------------------------------------------------------------------------------
 */

const columns: Column<Dtos.ILookup>[] = [
    {
        field: 'propertyName',
        title: 'Property Path',
    },
    {
        field: 'itemCount',
        title: 'Item Count',
        render: rowData => rowData.items?.length ?? 0
    }
]

const subcolumns: Column<Dtos.ILookupItem>[] = [
    {
        field: 'id',
        title: 'ID',
    },
    {
        field: 'value',
        title: 'Label'
    },
    {
        field: 'order',
        title: 'Order'
    },
    {
        field: 'type',
        title: 'Type'
    }
]

const Container = (props: any) => {
    return <Paper {...props} elevation={0} />;
}

const renderDetailPanel = (rowData: Dtos.ILookup) => {
    return (
        <div style={{ paddingLeft: 48, }}>
            <Table
                data={rowData.items ?? []}
                columns={subcolumns}
                options={{
                    paging: false,
                    toolbar: false
                }}
                components={{
                    Container: Container
                }}
            />
        </div>
    );
};

/**
 * This component provides context for the patient management system.
 * @param param0 component properties.
 */
const FormLookups: React.FunctionComponent<IFormLookupsProps> = ({
    children
}) => {
    const lookups = React.useContext(FormLookupsContext);

    return (
        <CollapsibleTable
            title="Lookups"
            loading={false}
            data={lookups ?? []}
            columns={columns}
            detailPanel={renderDetailPanel}
            options={{
                paging: false
            }}
            startCollapsed
        />
    );
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default FormLookups;