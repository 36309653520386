/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * This file contains the component that provides context for the online patient
 * management system.
 * ---------------------------------------------------------------------------------
 */

/*
 * ----------------------------------------------------------------------------------
 * Imports - External
 * ----------------------------------------------------------------------------------
 */

/*
 * Required to use React components.
 */
import * as React from 'react';


/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import * as Dtos from '../../api/dtos';
import LabelContext from '../../contexts/form/LabelContext';
import LabelsContext from '../../contexts/form/LabelsContext';
import FormLookupsContext from '../../contexts/form/FormLookupsContext';
import FormLookupContext from '../../contexts/form/FormLookupContext';
import ErrorContext from '../../contexts/form/ErrorContext';
import { Field, FormErrorContext, IFieldContext, IFieldProps as IFieldBaseProps } from '@ngt/forms-core';
// import { IFieldContext } from '@ngt/forms-core/src/contexts/FieldContext';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

type OmitProps<T> = Omit<T, 'onContextUpdate'>;

/**
 * This interface defines the properties for the Field component.
 */
export interface IFieldProviderProps<TValue = any> extends OmitProps<IFieldBaseProps<TValue, Dtos.IValidationError>> {
    children?: React.ReactNode;
}

/*
 * ---------------------------------------------------------------------------------
 * Components
 * ---------------------------------------------------------------------------------
 */

/**
 * This component provides context for the patient management system.
 * @param param0 component properties.
 */
const FieldProvider = <TValue extends any = any>({
    children,
    ...fieldBaseProps
}: IFieldProviderProps<TValue>) => {
    const [fieldContext, setFieldContext] = React.useState<IFieldContext<TValue, Dtos.IValidationError> | undefined>(undefined);

    const formErrorContext = React.useContext(FormErrorContext);
    const errorContext = React.useContext(ErrorContext);

    React.useEffect(() => {
        if (fieldBaseProps.autoRegister === false) {
            return () => { };
        }

        if (!fieldContext?.name) {
            return () => { };
        }

        formErrorContext?.addName(fieldContext?.name);

        return () => {
            if (fieldContext?.name) {
                formErrorContext?.removeName(fieldContext?.name);
            }
        }
    }, [formErrorContext?.addName, formErrorContext?.removeName, fieldContext?.name, fieldBaseProps.autoRegister]);

    const deindexedName = React.useMemo(() => {
        if (!fieldContext?.name) {
            return undefined;
        }

        return fieldContext?.name?.replace(/\[.*?\]/g, '');
    }, [fieldContext?.name])

    const labels = React.useContext(LabelsContext);

    const label = React.useMemo(() => {
        if (!deindexedName) {
            return undefined;
        }

        return labels?.find(l => l.name === deindexedName);
    }, [labels, deindexedName]);

    const lookups = React.useContext(FormLookupsContext);

    const lookup = React.useMemo(() => {
        if (!deindexedName) {
            return undefined;
        }

        return lookups?.find(l => l.propertyName === deindexedName);
    }, [lookups, deindexedName]);

    return (
        <FormLookupContext.Provider value={lookup}>
            <LabelContext.Provider value={label}>
                <Field
                    {...fieldBaseProps}
                    onContextUpdate={setFieldContext}
                >
                    {children}
                </Field>
            </LabelContext.Provider>
        </FormLookupContext.Provider>
    );
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default FieldProvider;