/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the hook used to get the form context.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to create a context.
 */
import * as React from 'react';

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */
import { IFieldState, IFieldSubscription } from '../FormManager';

import useField from './useField';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Functions
 * ---------------------------------------------------------------------------------
 */

const useScopedField = <TValue = any, TError = any>(path: string, subscription?: Partial<IFieldSubscription>, autoRegister?: boolean) => {
    const context = useField<TValue, TError>(path, autoRegister);

    const [state, setState] = React.useState<IFieldState<TValue, TError>>({
        value: context.getValue(),
        dirty: context.getDirty(),
        errors: context.getErrors(),
        focused: context.getFocused(),
        initialValue: context.getInitialValue(),
        touched: context.getTouched()
    });

    React.useEffect(() => {
        setState({
            value: context.getValue(),
            dirty: context.getDirty(),
            errors: context.getErrors(),
            focused: context.getFocused(),
            initialValue: context.getInitialValue(),
            touched: context.getTouched()
        });
    }, [context]);

    React.useEffect(() => {
        const unsubscribe = context.subscribe((fieldState) => {
            setState(fieldState)
        }, subscription as IFieldSubscription)

        return () => {
            unsubscribe();
        }
    }, [context.subscribe, subscription])

    const scopedField = React.useMemo(() => {
        return {
            state,
            context
        };
    }, [state, context])

    return scopedField;
};

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default useScopedField;