/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * This file contains the component that provides context for the online patient
 * management system.
 * ---------------------------------------------------------------------------------
 */

/*
 * ----------------------------------------------------------------------------------
 * Imports - External
 * ----------------------------------------------------------------------------------
 */

/*
 * Required to use React components.
 */
import * as React from 'react';


/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import useFieldState from '../hooks/useFieldState';
import useFieldActions from '../hooks/useFieldActions';
import { IFieldSubscription, IFieldState } from '../FormManager';
import ConditionContext from '../contexts/ConditionContext';
import useFormState from '../hooks/useFormState';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */


export type InputComponentType<T extends IInputRenderProps = any> = React.ComponentType<T>

export interface IInputRenderProps<TValue = any, TError = any> {
    inputRender: IInputRender<TValue, TError>;
}

export interface IInputRender<TValue = any, TError = any> {
    state: IInputState<TValue, TError>;
    actions: IInputActions<TValue, TError>;
}

export interface IInputState<TValue = any, TError = any> extends IFieldState<TValue, TError> {
    name: string;
}

// @ts-ignore: TError not used.
export interface IInputActions<TValue = any, TError = any> {
    update: (value: TValue | null | undefined, updateDirty?: boolean, updateTouched?: boolean, updateFocused?: boolean) => void;
    blur: () => void;
    focus: () => void;
    dirty: () => void;
    clean: () => void;
    touch: () => void;
    untouch: () => void;
}

/**
 * This interface defines the properties for the Input component.
 */
export interface IInputProps<
    TComponent extends InputComponentType = any,
    // @ts-ignore: TValue not used.
    TValue = any,
    // @ts-ignore: TError not used.
    TError = any,
    TInputValue = any
> {
    component?: TComponent;
    subscription?: Partial<IFieldSubscription>;
    parse?: (inputValue: TInputValue | null | undefined) => TValue | null | undefined;
    convert?: (fieldValue: TValue | null | undefined) => TInputValue | null | undefined;
}


export type OmitInputRender<T extends IInputRenderProps> = Omit<T, 'inputRender'>;

/*
 * ---------------------------------------------------------------------------------
 * Components
 * ---------------------------------------------------------------------------------
 */

/**
 * This component provides context for the patient management system.
 * @param param0 component properties.
 */
const Input = <TComponent extends InputComponentType = any, TValue = any, TError = any>({
    component,
    subscription,
    disabled,
    parse,
    convert,
    ...inputProps
}: IInputProps<TComponent, TValue, TError> & Partial<OmitInputRender<React.ComponentProps<TComponent>>>) => {
    const conditionContext = React.useContext(ConditionContext);
    const { submitting } = useFormState({ submitting: true });

    const isDisabled = React.useMemo(() => {
        return submitting || conditionContext?.conditionMet === false || disabled;
    }, [conditionContext?.conditionMet, submitting, disabled])

    const inputState = useFieldState<TValue, TError>(subscription);
    const { setValue, setFocused, setTouched, setDirty } = useFieldActions<TValue, TError>();

    const inputActions: IInputActions<TValue, TError> = React.useMemo(() => {
        return {
            blur: () => {
                setFocused(false);
            },
            clean: () => {
                setDirty(false);
            },
            dirty: () => {
                setDirty(true);
            },
            focus: () => {
                setFocused(true);
            },
            touch: () => {
                setTouched(true);
            },
            untouch: () => {
                setTouched(false);
            },
            update: (newValue: TValue | null | undefined, updateDirty?: boolean, updateTouched?: boolean, updateFocused?: boolean) => {
                const parsedValue = parse ?
                    parse(newValue) :
                    newValue;

                setValue(parsedValue, updateDirty, updateTouched, updateFocused);
            }
        }
    }, [setValue, setFocused, setTouched, setDirty]);

    const inputRender: IInputRender<TValue, TError> = React.useMemo(() => {
        const state = convert ?
            { ...inputState, value: convert(inputState.value) } :
            inputState;

        return {
            state,
            actions: inputActions
        };
    }, [inputState, inputActions])

    const Component: any = component;

    if (!Component) {
        return null;
    }

    return (
        <Component
            inputRender={inputRender}
            disabled={isDisabled}
            {...inputProps}
        />
    );
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default Input;