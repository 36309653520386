/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the function used to create the formDefinitions reducer.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to create a context.
 */
import * as React from 'react';

/*
 * Used to type the state of a request. 
 */
import { IRequestState, RequestState } from '@ngt/request-utilities';

/*
 * Used to create the reducer and associated actions.
 */
import { ImmerReducer, createReducerFunction, createActionCreators } from 'immer-reducer';

/*
 * Used to create side effects for the reducer.
 */
import { createLogic } from 'redux-logic';

/*
 * Used to type the reducer registry used to register reducers to the store.
 */
import { ReducerRegistry } from '@ngt/reducer-registry-logics';

/*
 * Used to create a typed selector hook.
 */
import { TypedUseSelectorHook, useSelector } from 'react-redux';

/*
 * Used to type the ServiceStack client.
 */
import { JsonServiceClient } from '@servicestack/client';

/*
 * Used to correctly type the created reducer.
 */
import { Reducer } from 'redux';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */


/*
 * Used to get access to backend types.
 */
import * as Dtos from '../../../api/dtos';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

export interface IFormDefinitionsState {
    formDefinitions: Dtos.IFormDefinition[] | null;
    loadState: IRequestState<Dtos.ResponseStatus>;
}

export interface IFormDefinitionsStore {
    formDefinitions: IFormDefinitionsState;
}


/*
 * ---------------------------------------------------------------------------------
 * Helper Fuctions
 * ---------------------------------------------------------------------------------
 */

const createContext = (formDefinitionId?: number, collaboratingGroupId?: number, countryId?: number) => {
    return `${(formDefinitionId ?? 'null')}-${(collaboratingGroupId ?? 'null')}-${(countryId ?? 'null')}`
}

/*
 * ---------------------------------------------------------------------------------
 * Classes
 * ---------------------------------------------------------------------------------
 */

/**
 * This class is used as a short had to describe all formDefinitions reducer state changes and actions. 
 */
export class FormDefinitionsReducer extends ImmerReducer<IFormDefinitionsState>
{
    public load() {
        this.draftState.loadState = {
            state: RequestState.Pending
        };
    }

    public loadSuccess(formDefinitions?: Dtos.IFormDefinition[]) {
        this.draftState.formDefinitions = formDefinitions ? formDefinitions : null;

        this.draftState.loadState = {
            state: RequestState.Success
        };
    }

    public loadFailure(responseStatus?: Dtos.ResponseStatus) {
        this.draftState.loadState = {
            state: RequestState.Failure,
            responseStatus: responseStatus
        };
    }

    public clear() {
        this.draftState = { ...initialFormDefinitionsState };
    }
}

/*
 * ---------------------------------------------------------------------------------
 * Constants
 * ---------------------------------------------------------------------------------
 */

export const initialFormDefinitionsState: IFormDefinitionsState = {
    formDefinitions: null,
    loadState: {
        state: RequestState.None
    }
}

export const formDefinitionsActions = createActionCreators(FormDefinitionsReducer);
export const formDefinitionsReducer = createReducerFunction(FormDefinitionsReducer, initialFormDefinitionsState);

const createFormDefinitionsApi = (client: JsonServiceClient) => ({
    load: () => {
        return client.get(new Dtos.FormDefinitionGetCollection());
    }
});

const createFormDefinitionsLogic = (api: ReturnType<typeof createFormDefinitionsApi>) => {
    const logic = {
        load: createLogic<IFormDefinitionsStore, {}, undefined, string, ReturnType<typeof formDefinitionsActions.load>>({
            type: formDefinitionsActions.load.type,
            process: async ({ action }, dispatch, done) => {
                try {
                    const response = await api.load();

                    dispatch(formDefinitionsActions.loadSuccess(
                        response.formDefinitions
                    ));
                }
                catch (error) {
                    dispatch(formDefinitionsActions.loadFailure(error ? error.responseStatus : undefined));
                }

                done();
            }
        })
    }

    return [
        logic.load
    ]
};

export const useFormDefinitionsSelector: TypedUseSelectorHook<IFormDefinitionsStore> = useSelector;

export const formDefinitionsSelectors = {
    formDefinitions: (state: IFormDefinitionsStore) => state.formDefinitions.formDefinitions,
    loadState: (state: IFormDefinitionsStore) => state.formDefinitions.loadState
}


/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

const registerFormDefinitionsReducer = (client: JsonServiceClient, reducerRegistry: ReducerRegistry) => {
    const api = createFormDefinitionsApi(client);

    const logic = createFormDefinitionsLogic(api);

    reducerRegistry.register('formDefinitions', formDefinitionsReducer as Reducer, logic as any);
};

export default registerFormDefinitionsReducer;