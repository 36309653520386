/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file sets up the logic for retrieving an individual patient.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/**
 * Used to create logic based side effects for the redux store.
 */
import { createLogic } from 'redux-logic';

/**
 * Typings
 */
import { TypedUseSelectorHook, useSelector, useDispatch } from 'react-redux';

/**
 * Utility library for reducing redux boilerplate while remaining typed. 
 */
import { ImmerReducer, createActionCreators, createReducerFunction } from 'immer-reducer';

/**
 * Typings:
 *  - IAuthSession
 */
import { JsonServiceClient } from '@servicestack/client';

/**
 * Enumeration used to determine the state of a request.
 */
import { RequestState, IRequestState } from '@ngt/request-utilities';


import { useState, useEffect } from 'react';

import { bindActionCreators, Reducer } from 'redux';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */


/*
 * Used to get access to the API types and requests
 */
import * as Dtos from '../../api/dtos';

import { ReducerRegistry } from '@ngt/reducer-registry-logics';

/*
* ---------------------------------------------------------------------------------
* Interfaces / Types
* ---------------------------------------------------------------------------------
*/

export interface IAuthenticatedUserState {
    user: Dtos.GetUserResponse | null;
    loadState: IRequestState<Dtos.ResponseStatus>;
}

export interface IAuthenticatedUserStore {
    authenticatedUser: IAuthenticatedUserState;
}

/*
 * ---------------------------------------------------------------------------------
 * Initial State
 * ---------------------------------------------------------------------------------
 */

const AuthenticatedUserInitialState: IAuthenticatedUserState = {
    user: null,
    loadState: {
        state: RequestState.None
    }
};

/*
* ---------------------------------------------------------------------------------
* Selectors
* ---------------------------------------------------------------------------------
*/

export const useAuthenticatedUserSelector: TypedUseSelectorHook<IAuthenticatedUserStore> = useSelector;

export const authenticatedUserSelectors = {
    selectUser: (state: IAuthenticatedUserStore) => state.authenticatedUser.user,
    selectLoadState: (state: IAuthenticatedUserStore) => state.authenticatedUser.loadState
};

/*
* ---------------------------------------------------------------------------------
* Reducer
* ---------------------------------------------------------------------------------
*/

export class AuthenticatedUserReducer extends ImmerReducer<IAuthenticatedUserState> {
    public checkAuthenticatedUser() {
        this.draftState.loadState = {
            state: RequestState.Pending
        };
    }

    public signoutAuthenticatedUser() {
        this.draftState.user = this.draftState.user;
    }

    public loadSuccess(user: Dtos.GetUserResponse) {
        this.draftState.user = user;
        this.draftState.loadState = {
            state: RequestState.Success
        };
    }

    public loadFailure(responseStatus: Dtos.ResponseStatus) {
        this.draftState.user = null;
        this.draftState.loadState = {
            state: RequestState.Failure,
            responseStatus
        };
    }

    public clear() {
        return { ...AuthenticatedUserInitialState };
    }
}

export const authenticatedUserActions = createActionCreators(AuthenticatedUserReducer);
export const authenticatedUserReducer = createReducerFunction(AuthenticatedUserReducer, AuthenticatedUserInitialState);

/*
 * ---------------------------------------------------------------------------------
 * API
 * ---------------------------------------------------------------------------------
 */

const createAuthenticatedUserApi = (client: JsonServiceClient) => ({
    checkAuthenticatedUser: () => {
        return client.post(new Dtos.GetUser());
    }
});

/*
 * ---------------------------------------------------------------------------------
 * Logic
 * ---------------------------------------------------------------------------------
 */

const createAuthenticatedUserLogic = (api: ReturnType<typeof createAuthenticatedUserApi>) => {
    const logics = {
        checkAuthenticatedUser: createLogic({
            type: authenticatedUserActions.checkAuthenticatedUser.type,
            latest: true,
            process: async ({ action }, dispatch, done) => {
                try {
                    const response = await api.checkAuthenticatedUser();
                    dispatch(authenticatedUserActions.loadSuccess(
                        response
                    ));
                }
                catch (error) {
                    dispatch(authenticatedUserActions.loadFailure(error ? error.responseStatus : undefined));
                }

                done();
            }
        })
    };

    return [
        logics.checkAuthenticatedUser
    ];
};

/*
 * ---------------------------------------------------------------------------------
 * Register
 * ---------------------------------------------------------------------------------
 */

const registerAuthenticatedUserReducer = (client: JsonServiceClient, reducerRegistry: ReducerRegistry) => {
    const api = createAuthenticatedUserApi(client);

    const logic = createAuthenticatedUserLogic(api);

    reducerRegistry.register('authenticatedUser', authenticatedUserReducer as Reducer, logic as any);
};

export default registerAuthenticatedUserReducer;