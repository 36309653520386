/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * This file contains the component that provides context for the Forms system.
 * ---------------------------------------------------------------------------------
 */

/*
 * ----------------------------------------------------------------------------------
 * Imports - External
 * ----------------------------------------------------------------------------------
 */

/*
 * Required to use React components.
 */
import * as React from 'react';

/*
 * Used to connect the redux store to the rest of the apps.
 */
import { Provider } from 'react-redux';

/*
 * Used to provide the MUI Theme to the rest of the app.
 */
import { ThemeProvider } from '@material-ui/core/styles';

/*
 * Used to provide the Date Functions required by MUI Date Pickers to the rest of the
 * app.
 */
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

import LuxonUtils from '@date-io/luxon';

import { Router } from 'react-router-dom';

import { SnackbarProvider } from 'notistack';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

/*
 * Used for Typings
 */
import Forms from '../Forms';

/*
 * Used to provide the Forms object to the rest of the app.
 */
import FormsContext from '../contexts/FormsContext';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

/**
 * This interface defines the properties for the FormsProvider component.
 */
export interface IFormsProviderProps {
    value: Forms
}

/*
 * ---------------------------------------------------------------------------------
 * Components
 * ---------------------------------------------------------------------------------
 */

/**
 * This component provides context for the patient management system.
 * @param param0 component properties.
 */
const FormsProvider: React.FunctionComponent<IFormsProviderProps> = ({
    value,
    children
}) => {

    if (!value || !(value instanceof Forms)) {
        throw new Error("Forms not provided");
    }

    const compiledChildren = React.useMemo(() => {
        let c = children;

        value?.extensions?.reverse().forEach(extension => {
            if (extension.ProviderComponent) {
                const Component = extension.ProviderComponent;

                c = (
                    <Component>
                        {c}
                    </Component>
                );
            }
        })

        return c;
    }, [value.extensions, children]);

    return (
        <FormsContext.Provider value={value}>
            <Provider store={value.store}>
                <ThemeProvider theme={value.theme}>
                    <MuiPickersUtilsProvider utils={LuxonUtils}>
                        <SnackbarProvider
                            maxSnack={1}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                        >
                            <Router history={value.history}>
                                {compiledChildren}
                            </Router>
                        </SnackbarProvider>
                    </MuiPickersUtilsProvider>
                </ThemeProvider>
            </Provider>
        </FormsContext.Provider>
    );
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default FormsProvider;