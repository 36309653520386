/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the reducer registry class used to control adding reducers
 * and logic to the redux store at run time.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * Used for extending base class:
 *      - ReducerRegistry
 */
import { ReducerRegistry as ReducerRegistryBase } from '@ngt/reducer-registry-logics';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 *

/**
 * This class handles the registering and storing of redux reducers and their 
 * associated side effects
 */
export class ActionReducerRegistry<ActionsType = any> extends ReducerRegistryBase {
    private actions: Record<string, ActionsType>;

    /**
     * This function registers actions associated with a reducer.
     * @param name The name of the reducer (where it will be placed in the store)
     * @param actions The actions
     */
    public registerActions(name: string, actions?: ActionsType): void {
        // Add actions if they has been provided, if it exists already then replace 
        // the existing actions.
        if (actions) {
            this.actions = { ...this.actions, [name]: actions };
        }
    }

    /**
     * This function retrieves register actions associated with a specific reducer.
     * @param name The name of the reducer (where it will be placed in the store)
     */
    public getActions(name: string): ActionsType | null {
        if (this.actions) {
            return this.actions[name];
        }

        return null;
    }
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default ActionReducerRegistry;