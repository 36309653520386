/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * This file contains the component that provides context for the online patient
 * management system.
 * ---------------------------------------------------------------------------------
 */

/*
 * ----------------------------------------------------------------------------------
 * Imports - External
 * ----------------------------------------------------------------------------------
 */

/*
 * Required to use React components.
 */
import * as React from 'react';

import ReactMarkdown, { MarkdownProps } from 'markdown-to-jsx';

import { Theme, Typography, Link, withStyles, makeStyles } from '@material-ui/core';

import { Link as RouterLink } from 'react-router-dom';


/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

/**
 * This interface defines the properties for the Markdown component.
 */
export interface IMarkdownProps extends Omit<MarkdownProps, 'options'> {

}

/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles(theme => ({
    markdown: {
        marginBottom: theme.spacing(0),
        marginTop: theme.spacing(0),

        '& > :first-child': {
            marginTop: theme.spacing(0)
        },

        '& > :last-child': {
            marginBottom: theme.spacing(0)
        }
    }
}));

/*
 * ---------------------------------------------------------------------------------
 * Components
 * ---------------------------------------------------------------------------------
 */

const styles = (theme: Theme) => ({
    listItem: {
        marginTop: theme.spacing(1),
    },
});

const options = {
    overrides: {
        span: {
            component: Typography,
            props: {
                variant: 'body1'
            }
        },
        h1: {
            component: Typography,
            props: {
                gutterBottom: true,
                variant: 'h1',
            },
        },
        h2: {
            component: Typography,
            props: {
                gutterBottom: true,
                variant: 'h2'
            }
        },
        h3: {
            component: Typography,
            props: {
                gutterBottom: true,
                variant: 'h3'
            }
        },
        h4: {
            component: Typography,
            props: {
                gutterBottom: true,
                variant: 'h4'
            },
        },
        h5: {
            component: Typography,
            props: {
                gutterBottom: true,
                variant: 'h5'
            },
        },
        h6: {
            component: Typography,
            props: {
                gutterBottom: true,
                variant: 'h6',
                paragraph: true
            },
        },
        p: {
            component: Typography,
            props: {
                paragraph: true
            }
        },
        a: {
            component: (props: any) => {
                const href: string | undefined = props?.href;
                const hrefLowerCase: string | undefined = href?.toLowerCase();

                if (hrefLowerCase?.includes('://') ||
                    hrefLowerCase?.startsWith('//')) {
                    return (
                        <Link {...props} />
                    );
                }

                return (
                    <Link
                        {...props}
                        component={RouterLink}
                        to={href}
                    />
                );
            }
        },
        li: {
            component: withStyles(styles)(({ classes, ...props }: any) => (
                <li className={classes.listItem}>
                    <Typography component="span" {...props} />
                </li>
            )),
        },
    },
};


/**
 * This component provides context for the patient management system.
 * @param param0 component properties.
 */
const Markdown: React.FunctionComponent<IMarkdownProps> = ({
    children
}) => {
    const classes = useStyles()

    return (
        <ReactMarkdown options={options} children={children} className={classes.markdown} />
    );
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default Markdown;