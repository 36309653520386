/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * This file contains the component that provides context for the online patient
 * management system.
 * ---------------------------------------------------------------------------------
 */

/*
 * ----------------------------------------------------------------------------------
 * Imports - External
 * ----------------------------------------------------------------------------------
 */

/*
 * Required to use React components.
 */
import * as React from 'react';

import { IFormSubscription, IFormState } from '../FormManager';
import useFormActions from '../hooks/useFormActions';
import ConditionContext from '../contexts/ConditionContext';
import FieldContext, { IFieldContext } from '../contexts/FieldContext';
import ConditionConfigurationContext, { ConditionMode, IConditionConfigurationContext } from '../contexts/ConditionConfigurationContext';


/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

/**
 * This interface defines the properties for the Form component.
 */
export interface IConditionProps<TValues extends object = any, TError = any> extends IInternalConditionProps<TValues, TError> {
    onConditionNotMet?: IConditionConfigurationContext<TValues, TError>['onConditionNotMet'] | null;
    view?: IConditionConfigurationContext<TValues, TError>['view'];
}

/**
 * This interface defines the properties for the Form component.
 */
interface IInternalConditionProps<TValues extends object = any, TError = any> {
    condition: (state: IFormState<TValues, TError>, parentPropertyPath?: string | null) => boolean;
    subscription?: Partial<IFormSubscription>;
    mode?: ConditionMode;
    registerParent?: boolean;
    children?: React.ReactNode;
}

/*
 * ---------------------------------------------------------------------------------
 * Components
 * ---------------------------------------------------------------------------------
 */

const Condition = <TValues extends object = any, TError = any>({
    view,
    onConditionNotMet,
    ...internalProps
}: IConditionProps<TValues, TError>) => {
    const conditionConfigurationContext = React.useContext(ConditionConfigurationContext);

    const newConditionConfigurationContext = React.useMemo(() => {
        return {
            onConditionNotMet: onConditionNotMet === null ? undefined : (onConditionNotMet ?? conditionConfigurationContext.onConditionNotMet),
            view: view ?? conditionConfigurationContext.view
        }
    }, [view, onConditionNotMet]);

    if (onConditionNotMet !== undefined || view !== undefined) {
        return (
            <ConditionConfigurationContext.Provider value={newConditionConfigurationContext}>
                <InternalCondition {...internalProps} />
            </ConditionConfigurationContext.Provider>
        );
    }


    return <InternalCondition {...internalProps} />
};

/**
 * This component provides context for the patient management system.
 * @param param0 component properties.
 */
const InternalCondition = <TValues extends object = any, TError = any>({
    condition,
    subscription,
    mode,
    registerParent,
    children
}: IConditionProps<TValues, TError>) => {
    const formActions = useFormActions<TValues, TError>();

    const conditionConfigurationContext = React.useContext(ConditionConfigurationContext);

    const parentContext = React.useContext(ConditionContext);

    const fieldContext: IFieldContext<any, TError> | null = React.useContext(FieldContext);

    const parentFieldName = fieldContext?.name;

    const [conditionMet, setConditionMet] = React.useState<boolean>(() => {
        if (parentContext?.conditionMet === false) {
            return false;
        }

        return condition({
            values: formActions.getValues(),
            dirty: formActions.getDirty(),
            errors: formActions.getErrors(),
            fields: formActions.getFields(),
            focused: formActions.getFocused(),
            initialValues: formActions.getInitialValues(),
            touched: formActions.getTouched(),
            validating: formActions.getValidating(),
            submitting: formActions.getSubmitting()
        }, parentFieldName);
    });

    React.useEffect(() => {
        let met = false;

        if (parentContext?.conditionMet !== false) {
            met = condition({
                values: formActions.getValues(),
                dirty: formActions.getDirty(),
                fields: formActions.getFields(),
                errors: formActions.getErrors(),
                focused: formActions.getFocused(),
                initialValues: formActions.getInitialValues(),
                touched: formActions.getTouched(),
                validating: formActions.getValidating(),
                submitting: formActions.getSubmitting()
            }, parentFieldName);
        }

        setConditionMet(met);
    }, [formActions, setConditionMet, condition, parentContext?.conditionMet, parentFieldName]);


    React.useEffect(() => {
        const unsubscribe = formActions.subscribe(
            (formState) => {

                let met = false;

                if (parentContext?.conditionMet !== false) {
                    met = condition(formState, parentFieldName);
                }

                setConditionMet(met);
            },
            subscription as IFormSubscription | undefined
        );

        return () => {
            unsubscribe();
        }
    }, [formActions.subscribe, subscription, condition, parentContext?.conditionMet, parentFieldName]);

    const [names, setNames] = React.useState<string[]>([]);
    
    const addName = React.useCallback((name: string) => {
        parentContext?.addName(name);

        setNames(currentNames => {
            if (currentNames.includes(name)) {
                return currentNames;
            }

            return [...currentNames, name];
        });
    }, [setNames, parentContext?.addName]);

    const removeName = React.useCallback((name: string) => {
        parentContext?.removeName(name);

        setNames(currentNames => {
            if (!currentNames.includes(name)) {
                return currentNames;
            }

            return currentNames.filter(n => n !== name);
        });
    }, [setNames, parentContext?.removeName]);

    // Conditionally register parent field to clear field on disable.
    React.useEffect(() => {
        if (registerParent && parentFieldName) {
            addName(parentFieldName);
        }

        return () => {
            if (registerParent && parentFieldName) {
                removeName(parentFieldName)
            }
        }
    }, [registerParent, parentFieldName, addName, removeName]);

    const context = React.useMemo(() => {
        if (parentContext && parentContext?.conditionMet === false) {
            return parentContext;
        }

        return {
            names,
            conditionMet,
            addName,
            removeName
        }
    }, [names, addName, removeName, conditionMet, parentContext]);

    React.useEffect(() => {
        if (!conditionMet) {
            if (conditionConfigurationContext.onConditionNotMet) {
                conditionConfigurationContext.onConditionNotMet(names, formActions);
            }
        }
    }, [conditionMet, names, formActions, conditionConfigurationContext]);

    const View = conditionConfigurationContext.view;

    return (
        <ConditionContext.Provider value={context}>
            <View conditionMet={context.conditionMet} mode={mode ?? ConditionMode.Enable} children={children} />
        </ConditionContext.Provider>
    );
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default Condition;