/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * This file contains the component that provides context for the online patient
 * management system.
 * ---------------------------------------------------------------------------------
 */

/*
 * ----------------------------------------------------------------------------------
 * Imports - External
 * ----------------------------------------------------------------------------------
 */

/*
 * Required to use React components.
 */
import * as React from 'react';

import { Typography, Collapse, Button } from '@material-ui/core';


/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */


// import { default as FormBase, IFormProps as IFormBaseProps } from '@ngt/forms-core';
import {Form as NgtForm, IFormProps as IFormBaseProps} from '@ngt/forms-core';

import FormErrors from './debug/FormErrors';

import FormInitialValues from './debug/FormInitialValues';

import FormValues from './debug/FormValues';

import FormTouched from './debug/FormTouched';

import FormDirty from './debug/FormDirty';

import FormFocused from './debug/FormFocused';

import * as Dtos from '../../api/dtos';

import FormState from './debug/FormState';

import LabelsContext, { IFormLabel } from '../../contexts/form/LabelsContext';

import FormLabels from './debug/FormLabels';

import FormLookupsContext from '../../contexts/form/FormLookupsContext';

import FormLookups from './debug/FormLookups';
import FormErrorHandler from './FormErrorHandler';
import FormErrorDisplay from './FormErrorDisplay';
import FormFields from './debug/FormFields';
import FormOptionsContext, { FieldErrorOption } from '../../contexts/form/FormOptionsContext';
import useForm from '../../hooks/form/useForm';
import { IDtoClass } from '../..';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

///**
// * This interface defines the properties for the Form component.
// */
//export interface IFormProps<TData extends Record<string, any> = Record<string, any>> extends UseFormOptions<TData> {
//    onSubmit: SubmitHandler<TData>
//    children?: React.ReactNode;
//}

type OmitCalculatedTypes<T> = Omit<T, 'onValidate' | 'initialValues' | 'lookups' | 'labels'>

/**
 * This interface defines the properties for the Form component.
 */
export interface IFormProps<TData extends Dtos.IForm> extends OmitCalculatedTypes<IFormBaseProps<TData>> {
    formType: IDtoClass<TData>;
    afterFormSave?: (form?: TData | null) => void;
    afterFormSaveAndReturn?: (form?: TData | null) => void;
    onCancel?: () => void;
    fieldErrors?: FieldErrorOption;
    canEdit?: boolean;
    children: React.ReactNode;
}


/*
 * ---------------------------------------------------------------------------------
 * Components
 * ---------------------------------------------------------------------------------
 */
const Form = <TData extends Dtos.IForm>({
    formType,
    afterFormSave,
    afterFormSaveAndReturn,
    onCancel,
    children,
    fieldErrors,
    canEdit,
    ...formProps
}: IFormProps<TData>) => {

    const {
       form,
       formDefinition,
       formName,
       labels,
       lookups,
       handleSubmit,
       validate,
       onFormSubmitAndReturn,
       onFormSubmit,
       onFormCancel,
       onFormSubmitFailure,
       onFormSubmitValidationFailure,
       readOnly
    } = useForm({ formType, afterFormSave, afterFormSaveAndReturn, onCancel, readOnly: !canEdit });

    const [showDebug, setShowDebug] = React.useState(false);

    const onShowDebugClick = React.useCallback(() => {
        setShowDebug(!showDebug);
    }, [showDebug, setShowDebug]);

    const formOptions = React.useMemo(() => {
        return {
            fieldErrors: fieldErrors !== undefined ? fieldErrors : 'default',
            readOnly: readOnly === true
        }
    }, [fieldErrors, readOnly]);

    return (
        <FormOptionsContext.Provider value={formOptions}>
            <FormLookupsContext.Provider value={lookups}>
                <LabelsContext.Provider value={labels}>
                    <NgtForm 
                        {...formProps}
                        initialValues={form ?? {} as any}
                        onValidate={validate}
                        onSubmit={handleSubmit}
                        onSubmitFailed={onFormSubmitFailure}
                        onSubmitValidationFailed={onFormSubmitValidationFailure}
                    >
                        <FormErrorHandler>
                            {children}
                            <FormErrorDisplay />
                            {
                                process.env.NODE_ENV !== 'production' && (
                                    <Button
                                        onClick={onShowDebugClick}
                                        color="secondary"
                                        fullWidth
                                    >
                                        Debug
                                    </Button>
                                )
                            }
                            <Collapse
                                in={showDebug}
                                mountOnEnter
                            >
                                {
                                    showDebug && (
                                        <>
                                            <FormState />
                                            <FormFields />
                                            <FormInitialValues />
                                            <FormValues /> 
                                            <FormTouched />
                                            <FormDirty />
                                            <FormFocused />
                                            <FormErrors />
                                            <FormLabels />
                                            <FormLookups /> 
                                        </>
                                    )
                                }
                            </Collapse>                        
                        </FormErrorHandler>
                    </NgtForm>
                </LabelsContext.Provider>
            </FormLookupsContext.Provider>
        </FormOptionsContext.Provider>
    );
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default Form;