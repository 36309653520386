/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the functions to bind action creators to the store while 
 * keeping their type property.
 * --------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * Import - External
 * --------------------------------------------------------------------------------
 */

/*
 * Used to bind actions to the store.
 */
import { bindActionCreators, Dispatch, ActionCreatorsMapObject } from "redux";

/*
 * --------------------------------------------------------------------------------
 * Functions
 * --------------------------------------------------------------------------------
 */


const bindActionCreatorsWithType = <Actions>(actions: ActionCreatorsMapObject<any>, dispatch: Dispatch) => {
    const boundActions = bindActionCreators(actions, dispatch);

    Object
        .keys(boundActions)
        .forEach(key => {
            (boundActions[key] as any).type = (actions[key] as any).type;
        });

    return boundActions as unknown as Actions;
};


/*
 * --------------------------------------------------------------------------------
 * Default Export
 * --------------------------------------------------------------------------------
 */

export default bindActionCreatorsWithType;
