/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * This file contains the component that provides context for the online patient
 * management system.
 * ---------------------------------------------------------------------------------
 */

/*
 * ----------------------------------------------------------------------------------
 * Imports - External
 * ----------------------------------------------------------------------------------
 */

/*
 * Required to use React components.
 */
import * as React from 'react';

import { get } from 'lodash-es';

import { Column } from 'material-table';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import { useFormState } from '@ngt/forms-core';

import getDeepKeys from '../../../utilities/getDeepKeys';

import CollapsibleTable from '../../table/CollapsibleTable';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

/**
 * This interface defines the properties for the FormInitialValues component.
 */
export interface IFormInitialValuesProps {

}

/*
 * ---------------------------------------------------------------------------------
 * Components
 * ---------------------------------------------------------------------------------
 */
const columns: Column<any>[] = [
    {
        field: 'propertyName',
        title: 'Property Path',
    },
    {
        field: 'value',
        title: 'Value'
    }
]

/**
 * This component provides context for the patient management system.
 * @param param0 component properties.
 */
const FormInitialValues: React.FunctionComponent<IFormInitialValuesProps> = ({
    children
}) => {
    const { initialValues } = useFormState({ initialValues: true });

    const data = React.useMemo(() => {
        return getDeepKeys(initialValues).map(key => ({ propertyName: key, value: get(initialValues, key) }));
    }, [initialValues]);

    return (
        <CollapsibleTable
            title="Field Initial Values"
            entityName="values"
            loading={false}
            data={data}
            columns={columns}
            options={{
                paging: false
            }}
            startCollapsed
        />
    );
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default FormInitialValues;