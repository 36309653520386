/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * This file contains the component that provides context for the online patient
 * management system.
 * ---------------------------------------------------------------------------------
 */

/*
 * ----------------------------------------------------------------------------------
 * Imports - External
 * ----------------------------------------------------------------------------------
 */

/*
 * Required to use React components.
 */
import * as React from 'react';

import { Column } from 'material-table';

import { Paper } from '@material-ui/core';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import { useFormState } from '@ngt/forms-core';

import * as Dtos from '../../../api/dtos';

import CollapsibleTable from '../../table/CollapsibleTable';

import Table from '../../table/Table';
import Markdown from '../../utility/Markdown';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

/**
 * This interface defines the properties for the FormErrors component.
 */
export interface IFormErrorsProps {

}

/*
 * ---------------------------------------------------------------------------------
 * Components
 * ---------------------------------------------------------------------------------
 */
const columns: Column<any>[] = [
    {
        field: 'propertyName',
        title: 'Property Path',
    },
    {
        field: 'errorCount',
        title: 'Error Count',
        render: rowData => rowData.errors?.length ?? 0
    }
]

const subcolumns: Column<Dtos.IValidationError>[] = [
    {
        field: 'code',
        title: 'Code',
    },
    {
        field: 'type',
        title: 'Type',
        render: rowData => {
            if (!rowData.type) {
                return '';
            }

            for (let prop in Dtos.ValidationErrorType) {
                if (Dtos.ValidationErrorType[prop] == rowData.type as any) {
                    return `${rowData.type} - ${prop}`;
                }
            }

            return `${rowData.type} - Unknown`;
        }
    },
    {
        field: 'message',
        title: 'Message'
    }
]

const Container = (props: any) => {
    return <Paper {...props} elevation={0} />;
}

const renderDetailPanel = (rowData: any) => {
    return (
        <div style={{ paddingLeft: 48, }}>
            <Table
                data={rowData.errors ?? []}
                columns={subcolumns}
                options={{
                    paging: false,
                    toolbar: false
                }}
                components={{
                    Container: Container
                }}
                detailPanel={renderFutherDetailsPanel}
            />
        </div>
    );
};

const renderFutherDetailsPanel = (rowData: Dtos.IValidationError) => {
    return (
        <div style={{ paddingLeft: 48 }}>
            <Markdown>
                {rowData?.detailedMessage}
            </Markdown>
        </div>
    );
};

/**
 * This component provides context for the patient management system.
 * @param param0 component properties.
 */
const FormErrors: React.FunctionComponent<IFormErrorsProps> = ({
    children
}) => {
    const { errors } = useFormState<any, Dtos.IValidationError>({ errors: true });

    const data = React.useMemo(() => {
        if (!errors) {
            return [];
        }

        return Object.keys(errors).filter(key => errors[key] && errors[key].length > 0).map(key => ({ propertyName: key, errors: errors[key] }))
    }, [errors])

    return (
        <CollapsibleTable
            title="Field Errors"
            entityName="errors"
            loading={false}
            data={data}
            columns={columns}
            options={{
                paging: false
            }}
            detailPanel={renderDetailPanel}
            startCollapsed
        />
    );
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default FormErrors;