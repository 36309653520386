/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the context for the currently selected form.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to create a context.
 */
import * as React from 'react';

/*
 * Used to type request states.
 */
import { RequestState } from '@ngt/request-utilities';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to get access to backend types.
 */
import * as Dtos from '../../../api/dtos';

/*
 * Used to pass form context to the rest of the app.
 */
import FormByIdContext from '../../../contexts/data/FormContext';

/*
 * Used to load collaborating groups
 */
import useFormById from '../../../hooks/data/useFormById';

/*
 * Used to show loading view.
 */
import RouteLoading from '../../route/RouteLoading';
import LookupsContext from '../../../contexts/utility/LookupsContext';
import FormDefinitionContext from '../../../contexts/configuration/FormDefinitionContext';
import useFormDefinitionByCode from '../../../hooks/configuration/useFormDefinitionByCode';
import useFormDefinitionById from '../../../hooks/configuration/useFormDefinitionById';
import useLookupsByFormDefinitionId from '../../../hooks/utility/useLookupsByFormDefinitionId';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

export interface IFormByIdResolverProps {
    formDefinitionCode: string;
    id: number | null,
    resolveBeforeLoad?: boolean | null;
    formMapping?: Record<string, IFormMapping>;
    allowCreate?: boolean
}

export interface IFormMapping {
    components: React.ReactNode;
}

/*
 * ---------------------------------------------------------------------------------
 * Constants
 * ---------------------------------------------------------------------------------
 */

const FormByIdResolver: React.FunctionComponent<IFormByIdResolverProps> = ({
    formDefinitionCode,
    id,
    resolveBeforeLoad,
    formMapping,
    allowCreate,
    children
}) => {
    const [form, loadState, saveState, actions] = useFormById(formDefinitionCode, id, true, allowCreate);

    const formContext = React.useMemo(() => {
        return {
            form,
            loadState,
            saveState,
            actions
        };
    }, [form, loadState, actions]);

    const [formDefinition, formDefinitionLoadState, formDefinitionActions] = useFormDefinitionByCode(formDefinitionCode, true);

    const formDefinitionContext = React.useMemo(() => {
        return {
            formDefinition,
            loadState: formDefinitionLoadState,
            actions: formDefinitionActions
        };
    }, [formDefinition, loadState, actions]);

    // get lookups for form.
    const loadLookups = !!formDefinition?.id;

    const [lookups, lookupsLoadState, lookupsActions] = useLookupsByFormDefinitionId(formDefinition?.id ?? 0, loadLookups);

    const lookupsContext = React.useMemo(() => {
        return {
            lookups,
            loadState: lookupsLoadState,
            actions: lookupsActions
        };
    }, [lookups, lookupsLoadState, lookupsActions]);

    const formDefCode = formDefinition?.code ? formDefinition.code : '';

    const conditionalContexts: React.ReactNode =
        !!formMapping && formDefCode && !!formMapping[formDefCode]?.components ?
            formMapping[formDefCode]?.components :
            children;

    const notLoading =
        formDefinitionContext.loadState.state !== RequestState.None &&
        formDefinitionContext.loadState.state !== RequestState.Pending &&
        formContext.loadState.state !== RequestState.None &&
        formContext.loadState.state !== RequestState.Pending &&
        lookupsContext.loadState.state !== RequestState.None &&
        lookupsContext.loadState.state !== RequestState.Pending;

    return (
        <FormDefinitionContext.Provider value={formDefinitionContext}>
            <FormByIdContext.Provider value={formContext}>
                <LookupsContext.Provider value={lookupsContext}>
                    {
                        !resolveBeforeLoad || notLoading ?
                            conditionalContexts :
                            <RouteLoading />
                    }
                </LookupsContext.Provider>
            </FormByIdContext.Provider>
        </FormDefinitionContext.Provider>
    );
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default FormByIdResolver;