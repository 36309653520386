/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * This file contains the component that provides context for the online patient
 * management system.
 * ---------------------------------------------------------------------------------
 */

/*
 * ----------------------------------------------------------------------------------
 * Imports - External
 * ----------------------------------------------------------------------------------
 */

/*
 * Required to use React components.
 */
import * as React from 'react';

import { Column } from 'material-table';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import { useFormState } from '@ngt/forms-core';

import CollapsibleTable from '../../table/CollapsibleTable';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

/**
 * This interface defines the properties for the FormDirty component.
 */
export interface IFormDirtyProps {

}

/*
 * ---------------------------------------------------------------------------------
 * Components
 * ---------------------------------------------------------------------------------
 */
const columns: Column<any>[] = [
    {
        field: 'propertyName',
        title: 'Property Path',
    },
    {
        field: 'dirty',
        title: 'Dirty'
    }
]

/**
 * This component provides context for the patient management system.
 * @param param0 component properties.
 */
const FormDirty: React.FunctionComponent<IFormDirtyProps> = ({
    children
}) => {
    const { dirty } = useFormState({ dirty: true });

    const data = React.useMemo(() => {
        if (!dirty) {
            return [];
        }

        return Object.keys(dirty).map(key => ({ propertyName: key, dirty: dirty[key] }))
    }, [dirty])

    return (
        <CollapsibleTable
            title="Dirty Fields"
            entityName="fields"
            loading={false}
            data={data}
            columns={columns}
            options={{
                paging: false
            }}
            startCollapsed
        />
    );
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default FormDirty;