/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the hook to use an formDefinition by id.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to create a context.
 */
import * as React from 'react';

/*
 * Used to type the state of a request. 
 */
import { IRequestState, RequestState } from '@ngt/request-utilities';

/*
 * Used to type actions.
 */
import { ActionCreators } from 'immer-reducer';

/*
 * Used to get access to dispatch to dispatch actions to the store.
 */
import { useDispatch } from 'react-redux';

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to get access to backend types.
 */
import * as Dtos from '../../api/dtos';

/*
 * used to type actions.
 */
import { FormDefinitionReducer, formDefinitionActions, useFormDefinitionSelector, formDefinitionSelectors, IFormDefinitionStore } from '../../store/modules/configuration/formDefinition';

/*
 * Used to remove the first parameter from the base action creator function type.
 */
import { OmitFirstArg } from '../../utilities/OmitArgs';

/*
 * Used to type action creators with first parameter bound to a static. 
 */
import { BoundActionCreator } from '../../utilities/BoundActionCreator';

/*
 * Used to bind action creators to the store but keep their assigned type property.
 */
import bindActionCreatorsWithType from '../../utilities/bindActionCreatorsWithType';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

export interface IUseFormDefinitionByCodeActions {
    load: BoundActionCreator<OmitFirstArg<typeof formDefinitionActions.loadByCode>>;
    clear: BoundActionCreator<OmitFirstArg<typeof formDefinitionActions.clearByCode>>;
}

/*
 * ---------------------------------------------------------------------------------
 * Functions
 * ---------------------------------------------------------------------------------
 */

const useFormDefinitionByCode = <FormDefinitionType extends Dtos.IFormDefinition>(code: string, autoLoad?: boolean): [
    FormDefinitionType | null,
    IRequestState<Dtos.ResponseStatus>,
    IUseFormDefinitionByCodeActions
] => {
    const dispatch = useDispatch();

    const actions: IUseFormDefinitionByCodeActions = React.useMemo(() => {
        const load = () => formDefinitionActions.loadByCode(code);
        load.type = formDefinitionActions.loadByCode.type;

        const clear = () => formDefinitionActions.clearByCode(code);
        clear.type = formDefinitionActions.clearByCode.type;

        return bindActionCreatorsWithType({
            load,
            clear
        }, dispatch);
    }, [formDefinitionActions, code, dispatch]);

    const formDefinitionSelector = React.useCallback((state: IFormDefinitionStore) => {
        return formDefinitionSelectors.formDefinitionByCode(state, code);
    }, [formDefinitionSelectors.formDefinitionByCode, code]);

    const loadStateSelector = React.useCallback((state: IFormDefinitionStore) => {
        return formDefinitionSelectors.loadStateByCode(state, code);
    }, [formDefinitionSelectors.loadStateByCode, code]);

    const formDefinition = useFormDefinitionSelector(formDefinitionSelector);

    const loadState = useFormDefinitionSelector(loadStateSelector);

    React.useEffect(() => {
        if (autoLoad) {
            actions.load();

            return () => {
                actions.clear();
            };
        }

        return () => { };
    }, [autoLoad, code]);

    return [
        formDefinition as FormDefinitionType | null,
        loadState,
        actions
    ];
};

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default useFormDefinitionByCode;